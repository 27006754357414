<mat-horizontal-stepper linear #stepper>

  <mat-step [stepControl]="(empleadoDatos | async)" [editable]="isEditable">
    <ng-template matStepLabel>Datos</ng-template>
    <div class="container mg-top-24">
      <app-datos #datos [dato]="empleado" (emisor)="datosEmpleado($event)"></app-datos>
    </div>
  </mat-step>


  <mat-step label="Contacto">
    <div class="container mg-top-24">
      <app-contacto #contacto [dato]="empleado" [stepper]="stepper"></app-contacto>
    </div>
  </mat-step>


  <mat-step label="Horario">
    <div class="container mg-top-24">
      <app-horario #horario [dato]="empleado" [stepper]="stepper"></app-horario>
    </div>
  </mat-step>


  <mat-step label="Estudios">
    <div class="container mg-top-24">
      <app-estudios #estudios [dato]="empleado" [stepper]="stepper"></app-estudios>
      <button type="button" class="btn btn-dark float-right" *ngIf="ok" (click)="guardar()">Guardar</button>
    </div>
  </mat-step>


  <mat-step label="Seguimiento" *ngIf="!ok">
    <div class="container mg-top-24">
      <app-control [dato]="empleado"></app-control>
    </div>
  </mat-step>

</mat-horizontal-stepper>

