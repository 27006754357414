import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SharedComponent} from './shared.component';

import {AuthGuardService as AuthGuard} from '../services/auth-guard.service';
import {AdminComponent} from '../admin/admin.component';
import {EmpleadosComponent} from '../admin/componentes/empleados/empleadoList/empleados.component';
import {AddempleadoComponent} from '../admin/componentes/empleados/empleadoAdd/addempleado.component';
import {SeguimientoComponent} from '../admin/componentes/empleados/seguimientoList/seguimiento.component';
import {EmpresaComponent} from '../admin/componentes/empresa/empresaList/empresa.component';
import {DepartamentoComponent} from '../admin/componentes/departamento/departamentoList/departamento.component';
import {DepartamentoaaddComponent} from '../admin/componentes/departamento/departamentoAdd/departamentoaadd.component';
import {EmpresaaddComponent} from '../admin/componentes/empresa/empresaAdd/empresaadd.component';
import {EmpresaLocalizacionComponent} from '../admin/componentes/empresa/empresa-localizacion/empresa-localizacion.component';
import {EmpresaLocalizacionMapComponent} from '../admin/componentes/empresa/empresa-localizacion-map/empresa-localizacion-map.component';
import {ProyectosComponent} from '../admin/componentes/proyectos/proyectos.component';


const routes: Routes = [
    {
        path: 'admin',
        component: SharedComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admin',
                component: AdminComponent,
                canActivate: [AuthGuard],
                children: [
                    // { path: 'location', component: AdminLocationComponent },
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'empleados',
                component: EmpleadosComponent,
                canActivate: [AuthGuard],
                children: [
                    // { path: 'location', component: AdminLocationComponent },
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'addempleado',
                component: AddempleadoComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'seguimiento',
                component: SeguimientoComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'editempleado/:id',
                component: AddempleadoComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'empresa',
                component: EmpresaComponent,
                canActivate: [AuthGuard],
                children: [
                    // { path: 'location', component: AdminLocationComponent },
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'empresaadd',
                component: EmpresaaddComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'departamento',
                component: DepartamentoComponent,
                canActivate: [AuthGuard],
                children: [
                    // { path: 'location', component: AdminLocationComponent },
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'departamentoadd',
                component: DepartamentoaaddComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'localizacion',
                component: EmpresaLocalizacionComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'localizacion/:id',
                component: EmpresaLocalizacionComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'mapa',
                component: EmpresaLocalizacionMapComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
            {
                path: 'proyectos',
                component: ProyectosComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: '**', pathMatch: 'full', redirectTo: 'admin'}
                ]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SharedRoutingModule {
}


