<div class="row">
    <div class="col-md-12 col-sm-12">

        <form class="form form-horizontal" [formGroup]="estudiosForm" autocomplete="off">
            <div class="col-md-12 col-sm-12 float-left ">
                <div class="input-group mb-3" *ngIf="role === 'ROLE_ADMIN'">
                    <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-graduation-cap"></i>
                        </button>
                    </div>
                    <input id="titul" class="form-control" placeholder="Titulación" type="text" formControlName="titulo">
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="open()">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>

                    <div class="input-group-prepend" *ngIf="role !== 'ROLE_ADMIN'">
                        <span class="input-group-text-contacto-title bg-dark text-light border-dark">Titulaciones</span>
                    </div>
                <div class="list-group" *ngIf="titulos && titulos.length > 0">
                    <ng-container *ngFor="let titulo of titulos; let i = index;">
                        <div class="input-group">
                            <input type="text" class="form-control border-dark" name="titul{{i}}" placeholder="{{titulo.titulo}}" disabled>
                            <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row" *ngIf="!ok">
                <div class="col-sm-12 text-center mt-3">
                    <button type="submit" mat-button matStepperPrevious class="btn btn-dark btn-sm center-block mr-2"><i
                            class="fas fa-arrow-alt-circle-left"></i> Anterior</button>
                    <button type="button" mat-button class="btn btn-dark btn-sm center-block" (click)="Siguiente3()"><i
                            class="fas fa-arrow-alt-circle-right"></i> Siguiente</button>
                </div>
            </div>
        </form>
    </div>

</div>
