<div class="col-xs-12 col-md-12 row">
  <div class="form-group col-md-6 col-xs-12">
    <div class="input-group mb-2">
      <div class="input-group-prepend ">
        <span class="input-group-text bg-dark text-light border-dark"
              id="basic-addon3">Seleccione fecha de inicio: </span>
      </div>
      <input type="date" class="form-control" [(ngModel)]="inicio" aria-describedby="basic-addon3"
             (change)="getentreFechasInicio(dato, inicio, fin)">
    </div>
  </div>
  <div class="form-group col-md-6 col-xs-12">
    <div class="input-group mb-2">
      <div class="input-group-prepend ">
        <span class="input-group-text bg-dark text-light border-dark" id="basic-addon">Seleccione fecha de fin: </span>
      </div>
      <input type="date" class="form-control" [(ngModel)]="fin"
             aria-describedby="basic-addon" (change)="getentreFechas(dato, inicio, fin)">
    </div>
  </div>
</div>
<div class="mat-elevation-z8">
</div>

<div class="card mb-3">
  <div class="card-header">
    <i class="fas fa-user-clock"></i>
    Seguimiento {{nombre}}
    <span class="float-right" *ngIf="role === 'ROLE_ADMIN' && arreglados.length > 0">
      <button class="btn-outline-dark" type="button" [ngClass]="editButton()" (click)="changeEditarMode()"
              title="Editar horas"><i class="fas fa-edit"></i>
      </button>
    </span>
  </div>
  <div class="card-body">
    <table class="table table-striped" width="100%" cellspacing="0" data-page-length="10"
           data-paging="true" *ngIf="editarMode">
      <tbody>
      <td>
        <input type="date" class="form-control fechaAdd" [(ngModel)]="fechaAdd" aria-describedby="basic-addon3">
      </td>
      <td>
        <ngb-timepicker name="entrada" [(ngModel)]="timeEntradaAdd"></ngb-timepicker>
      </td>
      <td>
        <ngb-timepicker name="salida" [(ngModel)]="timeSalidaAdd"></ngb-timepicker>
      </td>
      <td>
        <a class="btn btn-outline-dark m-0 btnAnyadir" title="Añadir fichaje"
           (click)="addFichaje()">
          <i class="fas fa-plus  text-primary btnAnyadirIcon"></i>
        </a>
      </td>
      </tbody>
    </table>
    <table class="table table-striped" id="dataTable" width="100%" cellspacing="0" data-page-length="10"
           data-paging="true" *ngIf="arreglados.length > 0">
      <thead class="thead-dark">
      <tr>
        <th>Día</th>
        <th>Hora Entrada</th>
        <th>Hora Salida</th>
        <th>Horas</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of arreglados | paginate: { itemsPerPage: 10, currentPage: actualPage }; let i = index">
        <td>{{data.dia | date: 'EEEE dd/MM/yyyy' | titlecase }}</td>
        <td>
          {{data.entrada | date: 'HH:mm' }}
          <span class="float-right" *ngIf="editarMode">
            <button class="btn btn-outline-dark m-0" data-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="collapseExample" title="Editar hora">
              <i class="fas fa-edit"></i>
            </button>
          </span>
          <div class="collapse" id="collapseExampleE{{i}}" *ngIf="editarMode">
            <ngb-timepicker class="ngb-timepickerTable" name="entrada"
                            [(ngModel)]="data.timeEntrada"></ngb-timepicker>
            <a class="btn btn-primary saveButtonSeguimientoEmpleadoEdit" data-toggle="collapse"
               href="#collapseExampleE{{i}}"
               role="button"
               aria-expanded="false" aria-controls="collapseExample" title="Guardar cambios"
               (click)="actualizarFichaje(i, 'ENTRADA')">
              <i class="fas fa-save"></i>
            </a>
          </div>
        </td>
        <td>
          <div *ngIf="''+data.entrada !== ''+data.salida">
            {{data.salida | date: 'HH:mm' }}
            <span class="float-right" *ngIf="editarMode">
              <a class="btn btn-outline-dark m-0" data-toggle="collapse" href="#collapseExampleS{{i}}" role="button"
                 aria-expanded="false" aria-controls="collapseExample" title="Editar hora">
                <i class="fas fa-edit"></i>
              </a>
            </span>
            <div class="collapse" id="collapseExampleS{{i}}" *ngIf="editarMode">
              <ngb-timepicker class="ngb-timepickerTable" name="salida"
                              [(ngModel)]="data.timeSalida"></ngb-timepicker>
              <a class="btn btn-primary saveButtonSeguimientoEmpleadoEdit" data-toggle="collapse"
                 href="#collapseExampleS{{i}}"
                 role="button"
                 aria-expanded="false" aria-controls="collapseExample" title="Guardar cambios"
                 (click)="actualizarFichaje(i, 'SALIDA')">
                <i class="fas fa-save"></i>
              </a>
            </div>
          </div>
        </td>
        <td>
          {{data.horas | minutos}}
          <span class="float-right" *ngIf="editarMode">
            <a class="btn btn-danger m-0" title="Eliminar fichaje"
               (click)="eliminarFichaje(data.id_entrada, data.id_salida, i)">
              <i class="fas editSeguimiento fa-trash "></i>
            </a>
          </span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <td colspan="3">
        <strong>Total Horas</strong>
      </td>
      <td>
        <strong>
          {{getTotalCost() | minutos}}
        </strong>
      </td>
      </tfoot>
    </table>
    <pagination-controls (pageChange)="actualPage = $event" *ngIf="arreglados.length > 0"></pagination-controls>
  </div>
  <div *ngIf="inicio && fin" class="card-footer text-muted flex py-2 px-3">
    <ng-container>
      <p class="small text-muted m-0 py-2">
        Entre {{inicio | date:'EEEE dd MMMM yyyy' | titlecase}} y {{fin | date:'EEEE dd MMMM yyyy' | titlecase}}
      </p>
    </ng-container>
    <div class="float-right ml-auto">
      <button type="submit" mat-button matStepperPrevious class="btn btn-dark btn-sm center-block m-2"><i
        class="fas fa-arrow-alt-circle-left"></i> Anterior
      </button>
      <button type="button" class="btn btn-outline-danger mb-0" (click)="modalPdf()">
        <i class="fas fa-file-pdf"></i>
      </button>
    </div>
  </div>
</div>
<!-- /.container-fluid -->

