import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nombreEmpleado'
})
export class NombreEmpleadoPipe implements PipeTransform {

  transform(id: number): string {

    const listaEmpleados = JSON.parse(localStorage.getItem('empleados'));


    return null;
  }

}
