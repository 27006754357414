import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CrudproyectosService} from './services/crudproyectos.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {User} from '../../../../modelos/user.modelo';
import {EmpleadosService} from '../empleados/services/empleados.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs/Subscription';
import {HorasCalculoPipe} from '../../../../pipes/horas-calculo.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit, OnDestroy {
  @ViewChild('TABLE') TABLE: ElementRef;
  disable = true;
  users: Array<User> = [];
  proyectos: any;
  usersSubscription: Subscription = null;
  modalProyect: FormGroup;
  modalItem: FormGroup;
  modalTaks: FormGroup;
  modalItemHoras: FormGroup;
  paginas: Array<any>;

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor(private _crud: CrudproyectosService, private modalService: NgbModal, private _empleados: EmpleadosService,
              private datePipe: DatePipe, private fb: FormBuilder, public activeModal: NgbActiveModal, private horas: HorasCalculoPipe) {
    const role = 2;
    this._empleados.getEmpleados(role);
    this.usersSubscription = this._empleados.users$.subscribe(res => {
      this.users = res;
      console.log(res);
    });
    this.crearFomulario();
  }

  ngOnInit() {
    this.getProyectos();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  crearFomulario() {
    this.modalProyect = this.fb.group({
      nombre: [''],
      descripcion: ['']
    });

    this.modalTaks = this.fb.group({
      nombre: [''],
      descripcion: ['']
    });

    this.modalItem = this.fb.group({
      nombre: [''],
      descripcion: ['']
    });

    this.modalItemHoras = this.fb.group({
      id_user: [''],
      id_tarea: [''],
      comentario: [''],
      hora_inicio: [''],
      hora_fin: [''],
      total: [{value: '', disabled: true}]
    });
  }

  getProyectos() {
    this._crud.getProyectos().subscribe(res => {
      console.log(res);
      this.proyectos = res;
    });
  }

  open(content) {
    this.modalService.open(content, {
      centered: true
    });
  }

  addProyecto(modal) {
    const nombre = this.modalProyect.value.nombre;
    const descripcion = this.modalProyect.value.descripcion;
    this._crud.setProyecto(nombre, descripcion).subscribe(resp => {
      console.log(resp);
      if (resp['code'] === 200) {
        this.getProyectos();
        this.modalProyect.reset();
        modal.dismiss();
      }
    });
  }

  addtarea(id, modal) {
    console.log(id);
    const nombre = this.modalTaks.value.nombre;
    const descripcion = this.modalTaks.value.descripcion;
    this._crud.setTarea(id, nombre, descripcion).subscribe(resp => {
      console.log(resp);
      if (resp['code'] === 200) {
        this.getProyectos();
        this.modalTaks.reset();
        modal.dismiss();
      } else {
        console.log(resp);
      }
    });
  }

  addItem(id, modal) {
    const nombre = this.modalItem.value.nombre;
    const descripcion = this.modalItem.value.descripcion;
    this._crud.setItem(id, nombre, descripcion).subscribe(resp => {
      if (resp['code'] === 200) {
      this.getProyectos();
      this.modalItem.reset();
      modal.dismiss();
      } else {
        console.log(resp);
      }
    });
  }

  pipeNombres(id) {
    const arrayNombres = this.users.filter(function (el) {
      return el.id === id;
    });
    return `${arrayNombres[0].nombreUser} ${arrayNombres[0].apellidos}`;
  }

  calculaHoras(inicio, fin) {
    if (fin != null) {
      const tempI = inicio.slice(0, 19);
      const tempF = fin.slice(0, 19);
      const datetimepartsInicio = tempI.split(/[- T :  ]/);
      const datetimepartsFin = tempF.split(/[- T : ]/);

      const dateIn = new Date(datetimepartsInicio[0],
        datetimepartsInicio[1],
        datetimepartsInicio[2],
        datetimepartsInicio[3],
        datetimepartsInicio[4],
        datetimepartsInicio[5]);

      const dateFin = new Date(datetimepartsInicio[0],
        datetimepartsFin[1],
        datetimepartsFin[2],
        datetimepartsFin[3],
        datetimepartsFin[4],
        datetimepartsFin[5]);

      const dif = dateFin.getTime() - dateIn.getTime();
      return dif;
    }
    return 0;
  }

  ExportTOExcel(type: number, proyectoID: number) {
    console.log(proyectoID);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.getProyecto(proyectoID));
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, this.proyectos[proyectoID].nombre);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  ExcelProyectos() {
    const wb = XLSX.utils.book_new();
      for (let i = 0; i < this.proyectos.length; i++) {
        const eachProduct = [];
        let proyectoName = '';
        for (let j = 0; j < this.proyectos[i].tareas.length; j++) {
          for (let k = 0; k < this.proyectos[i].tareas[j].items.length; k++) {
            for (let l = 0; l < this.proyectos[i].tareas[j].items[k].horas_fk.length; l++) {
              const item = this.proyectos[i].tareas[j].items[k].nombre;
              proyectoName = this.proyectos[i].nombre;
              const tareaName = this.proyectos[i].tareas[j].nombre;
              const horas_FK_ID = this.proyectos[i].tareas[j].items[k].horas_fk[l].user_id;
              const horas_FK_Usurio = this.pipeNombres(this.proyectos[i].tareas[j].items[k].horas_fk[l].user_id);
              const horas_FK_Comentario = this.proyectos[i].tareas[j].items[k].horas_fk[l].comentario;
              const horas_FK_Inicio = this.proyectos[i].tareas[j].items[k].horas_fk[l].inicio;
              const horas_FK_Fin = this.proyectos[i].tareas[j].items[k].horas_fk[l].fin;

              // Horas totales
              const dif = this.calculaHoras(horas_FK_Inicio, horas_FK_Fin);
              const minutos = ((dif / (1000 * 60)) % 60);
              const horas = ((dif / (1000 * 60 * 60)) % 24);
              const horas_FK_Total = `${Math.floor(horas)} h ${Math.floor(minutos)}`;
              eachProduct.push({
                'id': this.proyectos[i].id,
                'Proyecto': proyectoName,
                'Tarea': tareaName,
                'Item': item,
                'ID Usuario': horas_FK_ID,
                'Usuario': horas_FK_Usurio.toString(),
                'Comentario': horas_FK_Comentario,
                'Inicio': this.datePipe.transform(horas_FK_Inicio, 'yyyy-MM-dd HH:MM'),
                'Fin': this.datePipe.transform(horas_FK_Fin, 'yyyy-MM-dd HH:MM'),
                'Total': horas_FK_Total
              })

            }
          }
        }
        if (proyectoName) {
          const ws = XLSX.utils.json_to_sheet(eachProduct);
          XLSX.utils.book_append_sheet(wb, ws, proyectoName);
        }
      }

    XLSX.writeFile(wb, 'Proyecto.xlsx');
  }

  addItemhora(id, modal) {
    this.modalItemHoras.get('id_tarea').setValue(id);
    console.log(this.modalItemHoras.value);
    this._crud.itemHorasInicio(this.modalItemHoras.value).subscribe( (res: any) => {
      if (res.id) {
       this._crud.itemHorasFin(this.modalItemHoras.value, res.id).subscribe( resp => {
         if (resp['code'] === 200) {
           Swal.fire('', 'Datos Guardados Correctamente', 'success');
           this.getProyectos();
           this.modalItemHoras.reset();
           modal.dismiss();
         } else {
           console.log(resp);
         }
       });
      }
    });

  }

  horasCalcular() {
    const inicio = new Date(this.modalItemHoras.value.hora_inicio);
    const fin =  new Date(this.modalItemHoras.value.hora_fin);
    if (this.modalItemHoras.value.hora_fin) {
      const dif = fin.getTime() - inicio.getTime();
      this.modalItemHoras.get('total').setValue(this.horas.transform(dif));
    }
  }

  getProyecto(proyecto) {
    console.log(this.proyectos[proyecto]);
    const eachProduct = [];
    for (let j = 0; j < this.proyectos[proyecto].tareas.length; j++) {
      for (let k = 0; k < this.proyectos[proyecto].tareas[j].items.length; k++) {
        for (let l = 0; l < this.proyectos[proyecto].tareas[j].items[k].horas_fk.length; l++) {
          const proyectoName = this.proyectos[proyecto].nombre;
          const tareaName = this.proyectos[proyecto].tareas[j].nombre;
          const item = this.proyectos[proyecto].tareas[j].items[k].nombre;
          const horas_FK_Usurio = this.pipeNombres(this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].user_id);
          const horas_FK_Comentario = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].comentario;
          const horas_FK_Inicio = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].inicio;
          const horas_FK_Fin = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].fin;

          // Horas totales
          const dif = this.calculaHoras(horas_FK_Inicio, horas_FK_Fin);
          const minutos = ((dif / (1000 * 60)) % 60);
          const horas = ((dif / (1000 * 60 * 60)) % 24);
          const horas_FK_Total = `${Math.floor(horas)} h ${Math.floor(minutos)}`;

          eachProduct.push({
            'Proyecto': proyectoName,
            'Tarea': tareaName,
            'Item': item,
            'Usuario': horas_FK_Usurio,
            'Comentario': horas_FK_Comentario,
            'Inicio': this.datePipe.transform(horas_FK_Inicio, 'yyyy-MM-dd HH:MM'),
            'Fin': this.datePipe.transform(horas_FK_Fin, 'yyyy-MM-dd HH:MM'),
            'Total': horas_FK_Total
          });
        }
      }
    }
    return eachProduct;
  }



}
