<div class="row">
  <div class="col-md-12 col-sm-12">

    <form class="form form-horizontal" [formGroup]="horarioForm" autocomplete="off">

      <div class="col-md-12 col-sm-12 float-left ">
        <div class="input-group mb-5 crearHorarioRow" *ngIf="role === 'ROLE_ADMIN'">
          <div class="input-group-prepend">
            <button class="btn btn-dark opa-1" type="button" disabled>
              <i class="fas fa-calendar-check"></i>
            </button>
          </div>

          <select id="dia" class="form-control custom-select selectHorario" formControlName="dia">
            <option value="" selected>Dia de la semana</option>
            <option *ngFor="let dia of dias; let i=index;" [ngValue]="dia.valor">{{dia.nombre}}</option>
          </select>
          <div class="timePickerHorario"> Entrada
            <ngb-timepicker id="in" formControlName="in"></ngb-timepicker>
          </div>
          <div class="timePickerHorario"> Salida
            <ngb-timepicker id="out" formControlName="out"></ngb-timepicker>
          </div>
          <input id="margen" class="form-control selectHorario" placeholder="Margen minutos para fichar"
                 type="number" formControlName="margen">
          <div class="input-group-append">
            <button class="btn btn-dark" type="submit" (click)="open()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        <div class="list-group" *ngIf="horarios && horarios.length > 0">
          <ng-container *ngFor="let horario of horarios; let i = index;">
            <div class="input-group">
              <div class="input-group-prepend">
                <button class="btn btn-dark opa-1" type="button" disabled>
                  <i class="fas fa-calendar-check"></i>
                </button>
              </div>

              <!-- ************ DIA ******************-->
              <select class="form-control custom-select" disabled>
                <option value="horario.dia">{{diasemana(horario.dia)}}</option>
              </select>
              <!-- ************  HORA ENTRADA ******************-->
              <select *ngIf="horario.id" class="form-control custom-select" disabled>
                <option value="dias[horario.dia].nombre">{{horario.inat}}</option>
              </select>

              <!-- ************ HORA SALIDA ******************-->
              <select *ngIf="horario.id" class="form-control custom-select" disabled>
                <option value="dias[horario.dia].nombre">{{horario.outat}}</option>
              </select>
              <!-- ************ MARGEN ******************-->
              <input type="text" class="form-control" name="margen{{i}}" placeholder="{{horario.margen}}"
                     required readonly>

              <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center mt-3">
          <button  type="button" mat-button matStepperPrevious class="btn btn-dark btn-sm center-block mr-2"><i
            class="fas fa-arrow-alt-circle-left"></i> Anterior</button>
          <button type="button" mat-button class="btn btn-dark btn-sm center-block" (click)="Siguiente3()"><i
            class="fas fa-arrow-alt-circle-right"></i> Siguiente
          </button>
        </div>
      </div>

    </form>
  </div>

</div>
