import {Component, OnInit, Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe, TitleCasePipe} from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MinutosPipe} from '../../../../pipes/minutos.pipe';



export interface Fichaje {
    fecha: Date;
    entrada: Date;
    salida: Date;
    horas: number;
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-pdfmodal',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
    imageService: any;
    @Input() public fichajes: any[];
    @Input() public arreglados = new MatTableDataSource<Fichaje>();
    @Input() public user: any;
    @Input() public empresa: any;
    @Input() public inicio: any;
    @Input() public fin: any;
    @Input() public logo: any;

    public displaysColumns: string[] = ['fecha', 'entrada', 'salida', 'horas'];
    public dataSource: any;

    constructor(public activeModal: NgbActiveModal, private titlecasePipe: TitleCasePipe, private min: MinutosPipe,
                private dates: DatePipe) {
    }

    ngOnInit() {

    }

    getTotalCost() {
        if (this.fichajes) {
            return this.fichajes.map(t => t.horas).reduce((acc, value) => acc + value, 0);
        }
    }

    trans(value) {
        return this.dates.transform(value, 'dd-MM-yyyy');
    }

  fecha(fecha) {
    return this.dates.transform(fecha, 'dd/MM/yyyy');
  }

  convertirHora(hora) {
    return this.dates.transform(hora, 'HH:mm');
  }

  convertirMinutos(minutos) {
    return this.min.transform(minutos);
  }

  async generatePdf(action = 'download') {
    const documentDefinition = await this.getDocumentDefinition();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download(this.user.nombre + ' ' + this.user.apellidos + ' '
          + this.fecha(this.inicio) + ' ' + this.fecha(this.fin) + '.pdf');
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  async imagenPdf(url: string, width: number, height: number, alineacion: string, rowSpan: number) {
    if (rowSpan > 1) {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion,
        height: height,
        rowSpan: rowSpan,
        margin: [10, 20, 0, 20]
      };
    } else {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion,
        margin: [10, 10, 0, 15]
      };
    }
  }

  async getDocumentDefinition() {
    return {
      content: [
        {text: 'Resumen Registros de Horas', fontSize: 20, bold: true, margin: [0, 0, 10, 0]},
        {text: 'Datos de Empresa:', fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        {
          columns: [
            await this.imagenPdf('./assets/img/avanza.jpg', 100, 100, 'left', 0),
            {
              width: 'auto',
              text: this.titlecasePipe.transform(this.empresa.nombre) + '\n' +
                this.empresa.direccion + '  ' + this.empresa.municipio,
              margin: [20, 20, 0, 15],
            },
            {
              width: 'auto',
              text: this.empresa.username,
              margin: [35, 20, 0, 15],
            },
          ]
        },
        {text: 'Datos de Empleado:', fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        {
          columns: [
            {
              widths: ['*'],
              text: this.user.nombre + ' ' + this.user.apellidos,
              margin: [10, 20, 0, 20],
            },
            {
              widths: ['*'],
              text: this.user.dni,
              margin: [10, 20, 0, 20],
            },
            {
              widths: ['*'],
              text: this.fecha(this.user.nacimiento),
              margin: [10, 20, 0, 20],
            },
          ]
        },
        {text: 'Registros entre el ' + this.fecha(this.inicio) + ' y ' + this.fecha(this.fin), fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        this.getRegistro(),
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {
          columns: [
            {
              text: 'Total Horas',
              color: '#070707',
              fillColor: '#dedede',
              alignment : 'left',
              bold : true,
              margin: [0, 5, 0, 0]
            },
            {
              text: this.convertirMinutos(this.getTotalCost()),
              color: '#070707',
              fillColor: '#dedede',
              bold : true,
              margin: [136, 5, 0, 0]
            },
          ],
        },
        {
          columns: [
            {
              widths: ['*'],
              text: '--------------------------',
              margin: [90, 80, 0, 0],
            },
            {
              widths: ['*'],
              text: '--------------------------',
              margin: [90, 80, 0, 0],
            },
          ]
        },
        {
          columns: [
            {
              widths: ['*'],
              text: 'Firma Empresa',
              margin: [90, 0, 0, 0],
            },
            {
              widths: ['*'],
              text: 'Firma Empleado',
              margin: [90, 0, 0, 0],
            },
          ]
        },
      ],
    }
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    }); }

  getRegistro() {
      let i = 0;
    if (this.fichajes.length > 0) {
      return {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {text: 'Dia', style: 'tableHeader'},
              {text: 'Entrada', style: 'tableHeader'},
              {text: 'Salida', style: 'tableHeader'},
              {text: 'Horas', style: 'tableHeader'}
            ],
            ...this.fichajes.map(ed => {
              if (ed.entrada === 0 && ed.salida !== 0) {
                ed.entrada = ' ';
                // tslint:disable-next-line:max-line-length
                return [this.fecha(ed.dia), ed.entrada, this.convertirHora(ed.salida), this.convertirMinutos(ed.horas)];
              }
              if (ed.entrada !== 0 && ed.salida !== 0) {
                // tslint:disable-next-line:max-line-length
                return [this.fecha(ed.dia),  this.convertirHora(ed.entrada), this.convertirHora(ed.salida), this.convertirMinutos(ed.horas)];
              }

              if (ed.salida === 0 && ed.entrada !== 0) {
                ed.salida = ' ';
                // tslint:disable-next-line:max-line-length
                return [this.fecha(ed.dia),  this.convertirHora(ed.entrada), ed.salida, this.convertirMinutos(ed.horas)];
              }
              i++;
            })
          ]
        },
        layout: 'lightHorizontalLines',
      }
    } else {
      return {
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {text: 'Dia', style: 'tableHeader2'},
              {text: 'Entrada', style: 'tableHeader2'},
              {text: 'Salida', style: 'tableHeader2'},
              {text: 'Horas', style: 'tableHeader2'}
            ],
            [
              {}, {}, {},
            ],
          ]
        },
        layout: 'lightHorizontalLines',
      };
    }
  }

}
