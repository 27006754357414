import {
    Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef, TemplateRef
} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {FormGroup, NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Datos} from '../../../../../modelos/datos.modelo';
import {DatosComponent} from '../componentes/datos/datos.component';
import {ContactoComponent} from '../componentes/contacto/contacto.component';
import {EstudiosComponent} from '../componentes/estudios/estudios.component';
import {HorarioComponent} from '../componentes/horario/horario.component';
import {ControlComponent} from '../componentes/control/control.component';
import {MyModalComponent} from '../../../modales/my-modal/my-modal.component';
import {CrudempleadosService} from '../services/crudempleados.service';
import {UploadService} from '../../../../services/upload.service';
import {Rutas} from '../../../../../utiles/rutas';
import {EmpleadosService} from '../services/empleados.service';
import {PdfComponent} from '../../../modales/pdf/pdf.component';
import {LoginService} from '../../../../pages/services/login.service';
import swal from 'sweetalert2';

declare const $: any;

@Component({
    selector: 'app-editempleado',
    templateUrl: './editempleado.component.html'
})
export class EditempleadoComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DatosComponent) datos: DatosComponent;
    @ViewChild(ContactoComponent) contacto: ContactoComponent;
    @ViewChild(EstudiosComponent) estudios: EstudiosComponent;
    @ViewChild(HorarioComponent) horario: HorarioComponent;
    @ViewChild(ControlComponent) control: ControlComponent;

    public userSubscription = new Subscription();
    public empresaSubscription = new Subscription();
    public filesToUpload: Array<File>;
    public user: Datos;
    public ttlModal: string;
    public txtModal: string;
    public arreglados: any[];
    public empresa: any;
    public inicio: Date;
    public fin: Date;
    role = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _crud: CrudempleadosService,
        private _empleados: EmpleadosService,
        private _upload: UploadService,
        private _login: LoginService
    ) {

        this.role = this._login.getIdentity().role;


        this._route.params.subscribe(res => {
            if (res) {
                // console.log('respuesta', res);
                this._crud.getEmpleado(res.id);
                this._empleados.getAnosEmpleado(res.id);
                // this._empleados.getFichajesEmpleado(res.id);
            } else {
                this.ttlModal = 'ALERTA';
                this.txtModal = 'Fallo en la ruta, no hay id de usuario';
                $('#myModal').modal('show');
            }
        });
    }

    ngOnInit() {

        this.userSubscription = this._crud.user$.subscribe(res => {
            if (res) {
                // console.log(res);
                this.control.inicio = new Date();
                this.control.fin = new Date();
                this.control.inicio.setDate(1);

                // FORMATEAR INICIO Y FIN
                this.control.getentreFechas(res, this.dateAString(this.control.inicio), this.dateAString(this.control.fin));

                // TODO
                // LO BORRO, YA QUE DEVUELVE ERROR Y EN PRINCIPIO NO SE NECESITA
                this._crud.getEmpresa(res.id_centro);

                this.control.id = res.id;
                this.control.nombre = res.nombre;
                this.user = res;
                this.datos.dato = res;
                this.contacto.contactos = res;
                this.estudios.dato = res;
                this.horario.dato = res;
                this.control.dato = res;
                this.datos.provincia = res.id_provincia;
                this.datos.getMunicipios();
                this.datos.getDepartamentos();
                this.datos.imagen = Rutas + res.img;
                if (this.user.contactos === null || this.user.contactos.length === 0) {
                    // this.contacto.fantasma = null;
                } else {
                    // this.contacto.fantasma = 'lleno';
                }
                if (this.user.titulos === null || this.user.titulos.length === 0) {

                } else {

                }
            }
        });

        this.empresaSubscription = this._crud.empresa$.subscribe(res => {
            this.empresa = res;
        });


    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    ngAfterViewInit() {
    }

    guardar() {
        swal.fire('', 'Los datos se han guardado correctamente', 'success').then(() => this._crud.postUsuario(this.user));
    }

    dispararmodal(event) {
        this.ttlModal = event.ttlModal;
        this.txtModal = event.txtModal;
        $('#myModal').modal('show');
    }

    dispararpdfmodal(event) {
        // console.log('Dispara Modal', event);
        // console.log('Dispara Modal Arreglados', event.arreglados);
        this.arreglados = event.arreglados;
        this.inicio = event.inicio;
        this.fin = event.fin;
        $('#pdfMod').modal('show');
    }

    dateAString(date) {
        const fecha = date.toISOString();
        const trozos = fecha.split('T');
        return trozos[0];
    }

}
