<div class="row">
    <div class="col-md-12 col-sm-12">
        <form class="form form-horizontal" [formGroup]="contactoForm" autocomplete="off">
          <div class="row">
            <div class="col-md-6 col-sm-12 float-left ">
              <label>MÓVIL(*)</label>
              <div class="input-group mb-3" *ngIf="role === 'ROLE_ADMIN'">
                <div class="input-group-prepend">
                  <button class="btn btn-dark opa-1 min-w-42" type="button" disabled>
                    <i class="fas fa-mobile-alt "></i>
                  </button>
                </div>

                <input id="mov" class="form-control" placeholder="Móvil" type="tel"
                       [class.is-invalid]="movilnoValido" formControlName="movil">
                <div class="input-group-append">
                  <button class="btn btn-dark" type="button" [disabled]="movilnoValido" (click)="addMovil(2)">
                    <i class="icon-plus"></i>
                  </button>
                </div>
              </div>
              <small *ngIf="movilnoValido">
                <strong>El número de Móvil es requerido o no es Válido</strong>
              </small>
              <div class="list-group" *ngIf="contactos && contactos.length > 0">
                <div class="input-group-prepend" *ngIf="role !== 'ROLE_ADMIN'">
                  <span class="input-group-text-contacto-title bg-dark text-light border-dark">Móvil</span>
                </div>
                <ng-container *ngFor="let contacto of contactos; let i = index;">
                  <div *ngIf="contacto.tipo === 2" class="input-group">
                    <input type="text" class="form-control border-dark" name="movil{{i}}"
                           placeholder="{{contacto.dato}}" disabled>
                    <div class="input-group-append" id="button-addon3" *ngIf="role === 'ROLE_ADMIN'">
                      <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 float-left ">
              <label>FIJO</label>
              <div class="input-group mb-3" *ngIf="role === 'ROLE_ADMIN'">
                <div class="input-group-prepend">
                  <button class="btn btn-dark opa-1" type="button" disabled>
                    <i class="fas fa-phone"></i>
                  </button>
                </div>

                <input id="tel" class="form-control" placeholder="Teléfono" type="tel"
                       pattern="^(\+34|0034|34)?[6789][0-9]{8}$" [class.is-invalid]="telefonoNoValido" formControlName="telefono">
                <div class="input-group-append">
                  <button class="btn btn-dark" type="button" [disabled]="telefonoNoValido" (click)="addTelefono(3)">
                    <i class="icon-plus"></i>
                  </button>
                </div>
              </div>
              <small *ngIf="telefonoNoValido">
                <strong>El número Teléfono no es válido</strong>
              </small>
              <div class="list-group" *ngIf="contactos && contactos.length > 0">
                <div class="input-group-prepend" *ngIf="role !== 'ROLE_ADMIN'">
                  <span class="input-group-text-contacto-title bg-dark text-light border-dark">Teléfono</span>
                </div>
                <ng-container *ngFor="let contacto of contactos; let i = index;">
                  <div *ngIf="contacto.tipo === 3" class="input-group">
                    <input type="text" class="form-control border-dark" name="tel{{i}}"
                           placeholder="{{contacto.dato}}" disabled>
                    <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                      <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center mt-3">
              <button  type="button" mat-button matStepperPrevious class="btn btn-dark btn-sm center-block mr-2"><i
                class="fas fa-arrow-alt-circle-left"></i> Anterior</button>
              <button type="button" mat-button  class="btn btn-dark btn-sm center-block" (click)="Siguiente2()"><i
                class="fas fa-arrow-alt-circle-right"></i> Siguiente
              </button>
            </div>
          </div>
        </form>

    </div>
</div>

