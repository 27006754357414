<div class="row">
  <div class="col-lg-2 col-md-2 col-sm-12">
    <div class="imagen"
         [ngStyle]="{'background-image':(imagen && imagen !== '') ? 'url(' + imagen  + ')' : 'url(' + rutaimg + datosForm.value.img + ')'}"
         onclick="document.getElementById('buscadorPersona').click()"></div>

    <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event)"
           hidden [disabled]="soloLectura">
  </div>
  <div class="col-lg-10 col-md-10 col-sm-12">
    <form class="form form-horizontal" [formGroup]="datosForm" autocomplete="off" (ngSubmit)="Siguiente()">
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>NOMBRE(*)</label>
          <input class="form-control" type="text" [class.is-invalid]="nombreNoValido" placeholder="Nombre" name="nombre" minlength="2"
                 formControlName="nombre">
          <small *ngIf="nombreNoValido">
            <strong>El Nombre es Obligatorio</strong>
          </small>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>APELLIDOS(*)</label>
          <input class="form-control" type="text" [class.is-invalid]="apellidoNoValido" placeholder="Apellidos" name="apellidos" minlength="2"
                 formControlName="apellidos">
          <small *ngIf="apellidoNoValido">
            <strong>Debe indicar al menos el primer apellido</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>DNI/NIE(*)</label>
          <input class="form-control" type="text" [class.is-invalid]="dniNoValido" placeholder="DNI/NIE" name="dni" formControlName="dni">
          <small *ngIf="dniNoValido">
            <strong>Introduzca un DNI o NIE válido</strong>
          </small>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>Fecha Nacimiento</label>
          <input class="form-control" type="date"  name="nacimiento" formControlName="nacimiento">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>PROVINCIA(*)</label>
          <select formControlName="id_provincia" name="provincia" [class.is-invalid]="provinciaNoValido" class="form-control" (change)="getMunicipios()">
            <ng-container *ngIf="provincias.length> 0">
              <option value="">Elige Provincia</option>
              <option *ngFor="let provincia of provincias" [ngValue]="provincia.id">{{provincia.provincia}}</option>
            </ng-container>
          </select>
          <small *ngIf="provinciaNoValido">
            <strong>Debe Indicar la Provincia</strong>
          </small>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>MUNICIPIO(*)</label>
          <select formControlName="id_municipio" name="municipio" [class.is-invalid]="municipioNoValido" class="form-control" id="municipio">
            <option value="">Elige Municipio</option>
            <option *ngFor="let municipio of municipios" [ngValue]="municipio.id">{{municipio.municipio}}</option>
          </select>
          <small *ngIf="municipioNoValido">
            <strong>Debe Indicar el Municipio</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>DIRECCIÓN(*)</label>
          <input class="form-control" type="text" [class.is-invalid]="direccionNoValido" placeholder="Dirección" name="direccion" formControlName="direccion">
          <small *ngIf="direccionNoValido">
            <strong>Debe Indicar la dirección del Empleado</strong>
          </small>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>EMAIL(*)</label>
          <input class="form-control" type="email" [class.is-invalid]="emailNoValido" placeholder="Email" name="username" formControlName="username">
          <small *ngIf="emailNoValido">
            <strong>Introduzca un email válido</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>EMPRESA(*)</label>
          <select formControlName="id_centro" name="empresa" [class.is-invalid]="empresaNoValido" class="form-control" (change)="getDepartamentos()">
            <ng-container *ngIf="empresas.length > 0">
              <option value="">Elige una Empresa</option>
              <option *ngFor="let empresa of empresas" [ngValue]="empresa.id">{{empresa.nombre}}</option>
            </ng-container>
          </select>
          <small *ngIf="empresaNoValido">
            <strong>Debe Indicar la Empresa</strong>
          </small>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>DEPARTAMENTO(*)</label>
          <select formControlName="id_departamento" [class.is-invalid]="departamentoNoValido" name="id_departamento" class="form-control">
            <option value="">Elige Departamento</option>
            <option *ngFor="let departamento of departamentos"
                    [ngValue]="departamento.id">{{departamento.departnombre}}</option>
          </select>
          <small *ngIf="departamentoNoValido">
            <strong>Debe Indicar el Departamento</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>CONTRASEÑA</label>
          <input class="form-control" type="text" placeholder="Contraseña" name="password" formControlName="password">
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>PIN(*)</label>
          <input class="form-control" type="number" [class.is-invalid]="pinNoValido" placeholder="Pin" name="pin" formControlName="pin">
          <small *ngIf="pinNoValido">
            <strong>Debe indicar un PIN para que el trabador pueda fichar</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>JORNADA LABORAL(*)</label>
          <select formControlName="contrato" name="contrato" [class.is-invalid]="contratoNoValido" class="form-control" (change)="horas()">
            <option value="">Elige Tipo Contrato</option>
            <option *ngFor="let contrato of contratos; let i = index;"
                    [ngValue]="contrato.contrato">{{contrato.nombre}}</option>
          </select>
          <small *ngIf="contratoNoValido">
            <strong>Debe Indicar la Jornada Laboral de su Trabajador</strong>
          </small>

          <div class="row">
            <div class="col-sm-12  mt-1">
              <button class="btn btn-link btn-sm center-block" type="button" (click)="open(content)"><i class="fas fa-plus-square"></i>
                Añadir jornada
              </button>
            </div>

          </div>
        </div>

        <div class="form-group col-md-6 col-sm-12 floatL">
          <label>HORAS(*)</label>
          <input class="form-control" type="number" [class.is-invalid]="horasNoValido" placeholder="Horas Semanales" name="horas"
                 formControlName="horas">
          <small *ngIf="horasNoValido">
            <strong>Debe Indicar la Jornada Laboral de su Trabajador</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center mt-3">
          <button type="submit" mat-button matStepperNext class="btn btn-dark btn-sm center-block"><i
            class="fas fa-arrow-alt-circle-right"></i> Siguiente
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title " id="modal-basic-title">JORNADA PERSONALIZADA</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="modalForm" autocomplete="off">
      <div class="form-group">
        <label>Nombre de la jornada</label>
        <div class="input-group">
          <input class="form-control" formControlName="jornada" id="jornada" type="text" placeholder="Nombre"
                 name="jornada">
        </div>
      </div>
      <div class="form-group">
        <label>Horas</label>
        <div class="input-group">
          <input class="form-control" formControlName="jornadaHoras" id="jornadaHoras" type="number"
                 placeholder="Horas" name="jornadaHoras">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="submit()" class="btn btn-outline-dark">Añadir</button>
  </div>
</ng-template>

