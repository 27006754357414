<div>
  <h3 class="ml-5">Gestión de proyectos</h3>
</div>
<!-- Boton añadir proyecto-->
<div class="row">
  <div class="col-sm-12  mt-1">
    <button class=" ml-5 btn btn-link btn-md center-block" type="button" (click)="open(proyectoADD)">
      <i class="fas fa-plus-square"></i> Añadir proyecto
    </button>

    <button class=" ml-5 btn btn-link btn-md center-block" type="button" (click)="ExcelProyectos()">
      <i class="fas fa-plus-square"></i> Exportar todo a Excel
    </button>
  </div>
</div>

<!-- Acordeon proyectos -->
<div class="ml-5 mr-5 mt-1">
  <ngb-accordion #a="ngbAccordion" activeIds="proyecto-1">
    <ngb-panel *ngFor="let proyecto of proyectos; index as p">
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between ">
          <h5 class="m-0">{{ proyecto.nombre}}</h5>
          <!-- Div de botones en Titulo -->
          <div>
            <button class=" no-border bg-transparent "><i class="far fa-edit"></i></button>
            <button class=" no-border bg-transparent "><i class="far fa-trash-alt"></i></button>
            <button class=" no-border bg-transparent ">
              <i class="fas fa-file-export" (click)="ExportTOExcel(1, p)"> </i>
            </button>
            <button ngbPanelToggle class=" no-border bg-transparent"><i class="fas fa-grip-lines"></i>
            </button>
          </div>
        </div>

        <!-- Contenido de acordeon proyectos -->
      </ng-template>
      <ng-template ngbPanelContent>
        <label>{{ proyecto.descripcion}}</label>
        <!-- Boton añadir tarea-->
        <div class="row">
          <div class="col-sm-12  mt-1">
            <button class="  btn btn-link btn-md center-block" type="button" (click)="open(tareaADD)">
              <i class="fas fa-plus-square"></i> Añadir tarea
            </button>
          </div>
        </div>

        <!-- Acorderon tareas hijo de acordeon proyectos -->
        <ngb-accordion #a="ngbAccordion">
          <ngb-panel *ngFor="let tarea of proyecto.tareas; index as t">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="ml-2 ">{{ tarea.nombre}}</h5>
                <!-- Div de botones en Titulo de tareas -->
                <div>
                  <button class=" no-border bg-transparent ">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class=" no-border bg-transparent ">
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <button ngbPanelToggle class="no-border bg-transparent">
                    <i class="fas fa-grip-lines "></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <!-- Contenido acordeon Tareas -->
            <ng-template ngbPanelContent>
              <label>{{ tarea.descripcion}}</label>
              <!-- Boton añadir item -->
              <div class="row">
                <div class="col-sm-12  mt-1">
                  <button class="  btn btn-link btn-md center-block" type="button" (click)="open(itemADD)"><i
                    class="fas fa-plus-square"></i> Añadir item
                  </button>
                </div>
              </div>

              <ngb-accordion #a="ngbAccordion">
                <ngb-panel *ngFor="let item of tarea.items">
                  <ng-template ngbPanelHeader>
                    <div class="d-flex align-items-center justify-content-between">
                      <h5 class="m-0 ">{{ item.nombre}}</h5>
                      <!-- Div de botones en Titulo de items -->
                      <div>
                        <button class=" no-border bg-transparent "><i class="far fa-edit"></i>
                        </button>
                        <button class=" no-border bg-transparent "><i
                          class="far fa-trash-alt"></i></button>
                        <button ngbPanelToggle class="no-border  bg-transparent"><i
                          class="fas fa-grip-lines "></i></button>
                      </div>
                    </div>
                    <!-- Contenido de items -->
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <label>{{ item.descripcion}}</label>

                    <div class="col-sm-12  mt-1">
                      <button class="  btn btn-link btn-md center-block" type="button" (click)="open(itemHorasADD)">
                        <i class="fas fa-plus-square"></i> Añadir Horas Items
                      </button>
                    </div>

                    <!-- Listado de horas de items -->

                    <div class="card mb-3" *ngIf="item.horas_fk.length > 0">
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-striped" id="dataTable" width="100%" cellspacing="0"
                                 data-page-length="10" data-paging="true" #TABLE>
                            <thead class="thead-oscuro">
                            <tr>
                              <th colspan="6" hidden>{{proyecto.nombre}}</th>
                            </tr>
                            <tr>
                              <th colspan="6" hidden>{{item.nombre}}</th>
                            </tr>
                            <tr>
                              <th>ID</th>
                              <th>NOMBRE</th>
                              <th>COMENTARIOS</th>
                              <th>INICIO</th>
                              <th>FIN</th>
                              <th>TOTAL</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let hora of item.horas_fk; let i = index">
                              <td>{{hora.user_id }}</td>
                              <td>{{(pipeNombres(hora.user_id))}}</td>
                              <td>{{hora.comentario | titlecase}}</td>
                              <td>{{hora.inicio | date: ' dd/MM/yyyy HH:MM'}} </td>
                              <td>{{hora.fin | date: ' dd/MM/yyyy HH:MM'}} </td>
                              <td>{{ (calculaHoras(hora.inicio, hora.fin)) | horasCalculo }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <!-- Modal add item horas -->
                  <ng-template #itemHorasADD let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title " id="modal-basic-title-4">AÑADIR HORAS ITEM</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="modalItemHoras" (ngSubmit)="addItemhora(item.id, modal)">
                        <div class="form-group">
                          <label>Empleado</label>
                          <select formControlName="id_user" class="form-control" >
                            <option value="">Elige Empleado</option>
                            <option *ngFor="let empleado of users" [ngValue]="empleado.id">{{empleado.nombreUser}} {{empleado.apellidos}}</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label>Comentario</label>
                          <div class="input-group">
                            <textarea class="form-control" formControlName="comentario" type="text" rows="5"
                                      placeholder="comentario"> </textarea>
                          </div>
                        </div>
                        <div class="row" style="padding-left: 16px">
                          <div class="form-group"  style="margin-bottom: 0!important;">
                            <label>Hora de Inicio</label>
                            <div class="input-group">
                              <input class="form-control" formControlName="hora_inicio" type="datetime-local" (input)="horasCalcular()" required>
                            </div>
                          </div>

                          <div class="form-group" style="margin-left: 2rem; margin-bottom: 0!important;">
                            <label>Hora de Final</label>
                            <div class="input-group">
                              <input class="form-control" formControlName="hora_fin" type="datetime-local" (input)="horasCalcular()" required>
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label>Total</label>
                          <div class="input-group">
                            <input class="form-control" formControlName="total" type="text">
                          </div>
                        </div>
                        <div class="form-check">
                          <button type="submit" class="btn btn-outline-dark">Añadir</button>
                        </div>
                      </form>
                    </div>

                  </ng-template>

                </ngb-panel>
              </ngb-accordion>

              <!-- Modal add item -->
              <ng-template #itemADD let-modal>
                <div class="modal-header">
                  <h4 class="modal-title " id="modal-basic-title-3">AÑADIR ITEM</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="modalItem" (ngSubmit)="addItem(tarea.id, modal)">
                    <div class="form-group">
                      <label>Nombre del item</label>
                      <div class="input-group">
                        <input class="form-control" formControlName="nombre" id="itemName"
                               type="text" placeholder="Nombre del item." name="item"
                               required>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Descripición</label>
                      <div class="input-group">
                        <input class="form-control" formControlName="descripcion"
                               id="itemDescipcion" type="text"
                               placeholder="Descripción breve del ítem." name="jornadaHoras"
                               required>

                      </div>
                    </div>
                    <div class="form-check">
                      <button type="submit" class="btn btn-outline-dark">Añadir</button>
                    </div>
                  </form>
                </div>

              </ng-template>

            </ng-template>
          </ngb-panel>
        </ngb-accordion>


        <!-- Modal tarea -->
        <ng-template #tareaADD let-modal>
          <div class="modal-header">
            <h4 class="modal-title " id="modal-basic-title-2">AÑADIR TAREA</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="modalTaks" (ngSubmit)="addtarea(proyecto.id, modal)">
              <div class="form-group">
                <label>Nombre de la tarea</label>
                <div class="input-group">
                  <input class="form-control" formControlName="nombre" id="tareaName" type="text"
                         placeholder="Nombre de la tarea." name="tarea"
                         required>
                </div>
              </div>
              <div class="form-group">
                <label>Descripición</label>
                <div class="input-group">
          <textarea class="form-control " formControlName="descripcion" id="tareaDescipcion" type="text"
                    placeholder="Descripción breve de la tarea." name="jornadaHoras"
                    required></textarea>
                </div>
              </div>
              <div>
                <button type="submit" class="btn btn-outline-dark">
                  Añadir
                </button>
              </div>
            </form>
          </div>

        </ng-template>

      </ng-template>
    </ngb-panel>

  </ngb-accordion>

</div>
<!-- Modal add proyecto -->
<ng-template #proyectoADD let-modal>
  <div class="modal-header">
    <h4 class="modal-title " id="modal-basic-title">AÑADIR PROYECTO</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="modalProyect" (ngSubmit)="addProyecto(modal)" autocomplete="off">
      <div class="form-group">
        <label>Nombre del proyecto</label>
        <div class="input-group">
          <input class="form-control" formControlName="nombre" name="proyectoNombre" type="text"
                 placeholder="Nombre del proyecto.">
        </div>
      </div>
      <div class="form-group">
        <label>Descripición</label>
        <div class="input-group">
          <textarea class="form-control " formControlName="descripcion" name="proyectoDescripcion" type="text"
                    placeholder="Descripción breve del proyecto."
                    required></textarea>
        </div>
      </div>
      <div>
        <button type="submit" class="btn btn-outline-dark">Añadir</button>
      </div>
    </form>
  </div>

</ng-template>
