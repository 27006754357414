<mat-horizontal-stepper linear="false" #stepper>
    <mat-step label="Datos" >
        <div class="container mt-5">
            <app-datos (emisor)="dispararmodal($event)"></app-datos>
            <button type="button" class="btn btn-outline-dark d-block mr-0 ml-auto" (click)="guardar()" *ngIf="role === 'ROLE_ADMIN'">Guardar</button>
        </div>
    </mat-step>
    <mat-step label="Contacto" >
        <div class="container mt-5">
            <app-contacto (emisor)="dispararmodal($event)"></app-contacto>
        </div>
    </mat-step>
    <mat-step label="Horarios" >
        <div class="container mt-5">
            <app-horario (emisor)="dispararmodal($event)"></app-horario>
        </div>
    </mat-step>
    <mat-step label="Estudios" >
        <div class="container mt-5">
            <app-estudios (emisor)="dispararmodal($event)"></app-estudios>
        </div>
    </mat-step>
    <mat-step label="Seguimiento" >
        <div class="container mt-5">
            <app-control (pdfemisor)="dispararpdfmodal($event)"></app-control>
        </div>
    </mat-step>
</mat-horizontal-stepper>
<app-my-modal [titulo]="ttlModal" [texto]="txtModal"></app-my-modal>

