import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Rutas} from '../../../../../utiles/rutas';

@Injectable({
  providedIn: 'root'
})
export class CrudproyectosService {

  constructor(private http: HttpClient) { }


  setProyecto (nombrePro, descripcionPro) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const proyecto = {nombre: nombrePro, descripcion: descripcionPro };
    const json = { proyecto };
    const data = JSON.stringify(json);
    const params = 'token=' + localStorage.getItem('token') + '&json=' + data;
   //  console.log(params);
    return this.http.post(Rutas + 'add/proyecto', params, { headers: headers });
  }

  setTarea (id, nombreT, descripcionT) {
    // console.log(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const tarea = {nombre: nombreT, descripcion: descripcionT};
    const json = { tarea };
    const data = JSON.stringify(json);
    const params = '&token=' + localStorage.getItem('token') + '&json=' + data;
   // console.log(params);
    return this.http.post(Rutas + 'add/tarea?id=' + id, params, { headers: headers });
  }

  setItem (id, nombreT, descripcionT) {
    // console.log(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const item = {nombre: nombreT, descripcion: descripcionT};
    const json = { item };
    const data = JSON.stringify(json);
    const params = '&token=' + localStorage.getItem('token') + '&json=' + data;
    // console.log(params);
    return this.http.post(Rutas + 'add/item?id=' + id, params, { headers: headers });
  }

  getProyectos() {
  return this.http.get(Rutas + 'get/proyectos?token=' + localStorage.getItem('token'));
  }

  getProyecto(id) {
    return this.http.get(Rutas + 'get/proyecto?token=' + localStorage.getItem('token') + '&id=' + id);
  }

  itemHorasInicio(item ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const date = this.formatFecha(item.hora_inicio);
    const idUs = item.id_user;
    const idItem = item.id_tarea;
    const record = {
      idUser : idUs,
      idTarea : idItem,
      stampInicio: date
    };
    const json = { record };
    const data = JSON.stringify(json);
    const params = '&json=' + data;
    console.log(params);
    return this.http.post(Rutas + 'add/record' , 'token=' + localStorage.getItem('token')  + params , {headers});
  }

  itemHorasFin(item, idRec) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const date = this.formatFecha(item.hora_fin);
    const record = {
      idRecord : idRec,
      stampFin: date,
      comentario: item.comentario
    };
    const json = { record };
    const data = JSON.stringify(json);
    const params = '&json=' + data;
    console.log(params);
    return this.http.put(Rutas + 'update/record' , 'token=' + localStorage.getItem('token')  + params , {headers});
  }

  formatFecha(fecha): string {
    let date;
    date = new Date(fecha);
    date = date.getUTCFullYear() + '/' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) + '/' +
      ('00' + date.getUTCDate()).slice(-2) + ' ' +
      ('00' + date.getHours()).slice(-2) + ':' +
      ('00' + date.getMinutes()).slice(-2) + ':' +
      ('00' + date.getSeconds()).slice(-2);
    return date;
  }
}


