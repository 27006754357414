import {Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {LoginService} from '../../../../../pages/services/login.service';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {MatStepper} from '@angular/material/stepper';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-estudios',
  templateUrl: './estudios.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EstudiosComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dato: Datos;
  @Input() stepper: MatStepper
  @Output() public emisor = new EventEmitter<any>();
  estudiosForm: FormGroup;
  estudios$: Observable<FormGroup>;
  titulos: any[] = [];
  ok: boolean;
  role = '';

  private estudiosOb$ = new BehaviorSubject<FormGroup>(null);
  estudiosListener$: Observable<FormGroup> = this.estudiosOb$.asObservable();
  id: string;

  constructor(private _route: ActivatedRoute, private _router: Router, private _crud: CrudempleadosService, private _login: LoginService,
              private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.role = this._login.getIdentity().role;
    this.crearFormularios();
    this.ok = this._router.url === '/admin/addempleado';
  }

  setForm(form: FormGroup): any {
    this.estudiosOb$.next(form);
  }

  ngOnInit() {
    this.setForm(this.estudiosForm);
    this.estudios$ = this.estudiosListener$.pipe(delay(0));
  }

  ngOnDestroy() {

  }

  ngOnChanges() {
    if (this.dato) {
      this.titulos = this.dato.titulos;
    }
    this.cdRef.detectChanges();
  }

  crearFormularios() {
    this.estudiosForm = this.fb.group({
      titulo: ['']
    });
  }

  open() {
    if (this.estudiosForm.value.titulo) {
      if (!this.estudiosForm) {
        this.titulos = [];
      }
      const titulo = {
        id: this.dato.id,
        titulo: this.estudiosForm.value.titulo
      }
      this._crud.addTitulo(titulo).subscribe(res => {
        // console.log(res);
        if (res['code'] === 200) {
          const resp = res['id'];
          this.titulos = resp.titulos;
          this.estudiosForm.reset();
        }
      }, error => {
        console.log(error);
        return error;
      });
    } else {
      Swal.fire('', 'Faltan datos por completar', 'warning');
    }
  }


  borrar(i) {
    const idt = this.titulos[i].id;
    this._crud.removeTitulo(this.dato.id, idt).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        const resp = data['id'];
        this.titulos = resp.titulos;
      }
    }, error => {
      // console.log(error);
      return error;
    });
  }

  controlPaso(): boolean {
    if (!this.titulos) {
      this.titulos = [];
    }
    console.log(this.titulos);
    return this.titulos.length > 0;
  }

  Siguiente3() {
    /*if (!this.controlPaso()) {
      Swal.fire('', 'Es obligtorio ingresar al menos un Titulo', 'warning')
    } else {*/
      this.stepper.next();
   // }
  }
}
