<div class="card">
    <h5 class="card-header">Datos Empresa</h5>
    <div class="card-body">
            <form  class="form form-horizontal" ngNativeValidate #datosForm="ngForm" (ngSubmit)=crearEmpresa()>
            <div class="row pl-3 pr-3 mt-3">
                <div class="form-group col-md-6 col-sm-6 ">
                    <input class="form-control" type="text" placeholder="CIF" name="cif"  pattern="^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$" [(ngModel)]="cif" required oninvalid="setCustomValidity('Debe introducir un CIF válido')" oninput="setCustomValidity('')">
                </div>
                <div class="form-group col-md-6 col-sm-6 ">
                    <input class="form-control" type="text" placeholder="Nombre" name="nombre" minlength="5" [(ngModel)]="nombre"
                           required>
                </div>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="id_provincia" name="provincia" class="form-control" (change)="getMunicipios()">
                    <ng-container *ngIf="provincias.length > 0">
                        <option selected value="0">Elige Provincia</option>
                        <option *ngFor="let provincia of provincias"
                                [value]="provincia.id">{{provincia.provincia}}</option>
                    </ng-container>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="id_municipio" name="id_municipio" class="form-control"
                        [disabled]="municipios.length <= 0">
                    <option selected value="0">Elige Municipio</option>
                    <option *ngFor="let municipio of municipios" [value]="municipio.id">{{municipio.municipio}}</option>
                </select>

            </div>

            <div class="row pr-3 pl-3">
                <app-empresa-localizacion-map (Eventolongitud)="longitud=$event" (Eventolatitud)="latitud=$event" (Eventodireccion)="direccion=$event" (Eventoradio)="radio=$event" class="col-12"></app-empresa-localizacion-map>
            </div>

            <button type="submit" class="btn  btn-outline-dark btn-lg float-right mr-3">Guardar</button>

        </form>

    </div>
</div>
