import {Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, Output, EventEmitter, Input, ChangeDetectorRef, OnChanges
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {LoginService} from '../../../../../pages/services/login.service';
import {MatStepper} from '@angular/material/stepper';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactoComponent implements OnInit, OnDestroy, OnChanges {
  contactoForm: FormGroup;
  @Input() dato: Datos;
  @Output() public emisor = new EventEmitter<any>();
  @Input() stepper: MatStepper;
  contactos: any;
  role: string;
  contacto$: Observable<FormGroup>;

  private contactoOb$ = new BehaviorSubject<FormGroup>(null);
  contactoListener$: Observable<FormGroup> = this.contactoOb$.asObservable();

  constructor(private _route: ActivatedRoute, private fb: FormBuilder, private _router: Router, private _crud: CrudempleadosService,
              private renderer: Renderer2, private _login: LoginService, private cdRef: ChangeDetectorRef) {
    this.role = this._login.getIdentity().role;
    this.crearFormulario();
  }

  setForm(form: FormGroup): any {
    this.contactoOb$.next(form);
  }

  ngOnInit(): void {
    this.setForm(this.contactoForm);
    this.contacto$ = this.contactoListener$.pipe(delay(0));
  }

  ngOnDestroy() {

  }

  ngOnChanges() {
    if (this.dato) {
      console.log(this.dato);
      this.contactos = this.dato.contactos;
    }
    this.cdRef.detectChanges();
  }

  get movilnoValido(): any {
    return this.contactoForm.get('movil').invalid && this.contactoForm.get('movil').touched;
  }

  get telefonoNoValido(): any {
    return this.contactoForm.get('telefono').invalid && this.contactoForm.get('telefono').touched;
  }

  crearFormulario(): any {
    this.contactoForm = this.fb.group({
      movil: ['', [Validators.required, Validators.pattern('^(\\+34|0034|34)?[6789]\\d{8}$')]],
      telefono: ['', Validators.pattern('^(\\+34|0034|34)?[6789]\\d{8}$')]
    });
  }

  addMovil(tipo) {
    if (this.contactoForm.value.movil) {
      if (!this.contactos) {
        this.contactos = [];
      }
      const contacto = {
        id: this.dato.id,
        dato: this.contactoForm.value.movil,
        tipo: tipo,
      };
      this._crud.addContacto(contacto).subscribe(res => {
         console.log(res);
        if (res['code'] === 200) {
          this.contactos.push(contacto);
          this.contactoForm.reset();
        }
      }, error => {
        // console.log(error);
        return error;
      });
    }
  }

  addTelefono(tipo) {
    if (this.contactoForm.value.telefono) {
      if (!this.contactos) {
        this.contactos = [];
      }
      const contacto = {
        id: this.dato.id,
        dato: this.contactoForm.value.telefono,
        tipo: tipo,
      };
      this._crud.addContacto(contacto).subscribe(res => {
        // console.log(res);
        if (res['code'] === 200) {
          const resp = res['id'];
          this.contactos = resp.contactos;
          this.contactoForm.reset();
        }
      }, error => {
        // console.log(error);
        return error;
      });
    }
  }

  borrar(i) {
    const idc = this.contactos[i].id;
    this._crud.removeContacto(this.dato.id, idc).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        const resp = data['id'];
        this.contactos = resp.contactos;
      }
    }, error => {
      // console.log(error);
      return error;
    });
  }

  // PARA VERIFICAR QUE HAY MINIMO UN MOVIL
  controlPaso(): boolean {
    let haymovil = false;
    if (!this.contactos) {
      this.contactos = [];
    }
    console.log(this.contactos);
    for (let i = 0; i < this.contactos.length; i++) {
      if (this.contactos[i].tipo === 2) {
        haymovil = true;
        return true;
      }
    }
    if (!haymovil) {
      return false;
    }
  }

  Siguiente2() {
    if (!this.controlPaso()) {
      Swal.fire('', 'Debe ingresar al menos un Número de Móvil', 'warning');
    } else {
      this.stepper.next();
    }
  }


}
