import {Injectable} from '@angular/core';
import {Rutas} from '../../../../../utiles/rutas';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService {

    public empresasSource = new Subject<any>();
    public empresas$ = this.empresasSource.asObservable();

    public empresasRemoveSource = new Subject<any>();
    public empresasRemove$ = this.empresasRemoveSource.asObservable();

    empresa: Observable<any>;


    constructor(private http: HttpClient,
                private activateRouter: ActivatedRoute,
                private router: Router) {
    }

    getEmpresas() {
        this.http.get(Rutas + 'get/empresas?token=' + localStorage.getItem('token')).subscribe(res => {

            if (res['code'] === 200) {
                return res;
                // LO COMENTO, NO HACE NADA
                /*        console.log(res['id']);
                          this.empresasSource.next(res['id']);*/
            }
        }, error => {
            console.log(error);
        });
    }

    getEmpresaPorId(id: string) {
        this.empresa = this.http.get(Rutas + 'get/empresa?token=' + localStorage.getItem('token') + '&id=' + id);
        return this.empresa;
    }

    removeEmpresa(id: Number) {
        return this.http.get(Rutas + 'remove/empresa?id=' + id + '&token=' + localStorage.getItem('token'));
    }

    updateConfigFicharEmpresa(configFichar: any) {
        console.log('SERVICIO UPDATE CONFIG FICHAR');
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {configFichar};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        console.log(Rutas + 'update/cfichar', params, {headers: headers});
        return this.http.post(Rutas + 'update/cfichar', params, {headers: headers});
    }

    addEmpresaMap(createEmpresaMap: any) {
        console.log('ADD EMPRESA MAP');
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {createEmpresaMap};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        return this.http.post(Rutas + 'add/new/empresamap', params, {headers: headers});
    }


    addEmpresa(empresa: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {empresa};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        this.http.post(Rutas + 'add/new/empresa', params, {headers: headers}).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                console.log('Empresa Insertada');
                this.router.navigateByUrl('/admin/empresa');
            }
        }, error => {
            console.log(error);
        });
    }



}
