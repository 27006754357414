import { Injectable } from '@angular/core';
import {Rutas} from '../../../utiles/rutas';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FicharService {

  private respuestaSource = new Subject<any>();
  public respuesta$ = this.respuestaSource.asObservable();

  constructor(private http: HttpClient) { }

  postfichaje(user: any) {

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = { user };
    const data = JSON.stringify(json);
    const params = 'json=' + data;
    this.http.post(Rutas + 'fichar/tablet', params, { headers: headers }).subscribe(res => {
      if (res['code'] === 200) {
        console.log(res);
        this.respuestaSource.next(res);
      }
      else{
        this.respuestaSource.next(res);
      }
    }, error => {
      console.log(error);
    });
  }
}
