import {Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter, Input, ChangeDetectorRef, OnChanges
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {UploadService} from '../../../../../services/upload.service';
import {RutasImg} from '../../../../../../utiles/rutas';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoginService} from '../../../../../pages/services/login.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DatosComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dato: any;
  datosForm: FormGroup;
  @Output() public emisor = new EventEmitter<any>();

  public provinciasSubscription: Subscription = new Subscription();
  public municipiosSubscription: Subscription = new Subscription();
  public empresasSubscription: Subscription = new Subscription();
  public departamentosSubscription: Subscription = new Subscription();
  public contratosSubscription: Subscription = new Subscription();
  public provincias: any[] = [];
  public municipios: any[] = [];
  public empresas: any[] = [];
  public departamentos: any[] = [];
  public contratos: any[] = [];
  public filesToUpload: Array<File>;
  public rutaimg: any;
  public imagen: any;
  public provincia: any;
  soloLectura = true;
  public contrato: any;
  cambiarImagen = false;
  datos$: Observable<FormGroup>;

  modalForm: FormGroup;

  userSubscription = new Subscription();

  private datosOb$ = new BehaviorSubject<FormGroup>(null);
  datosListener$: Observable<FormGroup> = this.datosOb$.asObservable();


  constructor(private _crud: CrudempleadosService, private _upload: UploadService, private snackBar: MatSnackBar, private date: DatePipe,
              private _login: LoginService, private modalService: NgbModal, private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
    if (this._login.getIdentity().role === 'ROLE_ADMIN') {
      this.soloLectura = false;
    }
    this.rutaimg = RutasImg;
    this._crud.getProvincias();
    this._crud.getEmpresas();
    this._crud.getContratos();
    this.crearFormulario();
    this.contrato = {
      nombre: '',
      horas: 0,
      contrato: 0,
    }
  }

  setForm(form: FormGroup): any {
    this.datosOb$.next(form);
  }

  ngOnInit() {
    this.provinciasSubscription = this._crud.provincias$.subscribe(res => {
      this.provincias = res;
    });

    this.empresasSubscription = this._crud.empresas$.subscribe(res => {
      this.empresas = res;
    });

    this.contratosSubscription = this._crud.contratos$.subscribe(res => {
      this.contratos = res;
    });

    this.setForm(this.datosForm);
    this.datos$ = this.datosListener$.pipe(delay(0));

  }

  ngOnDestroy() {
    this.provinciasSubscription.unsubscribe();
    this.municipiosSubscription.unsubscribe();
    this.empresasSubscription.unsubscribe();
    this.departamentosSubscription.unsubscribe();
    this.contratosSubscription.unsubscribe();
    this.empresasSubscription.unsubscribe();

  }
  ngOnChanges() {
    if (this.dato) {
      this.setDatos(this.dato);
    }
    this.cdRef.detectChanges();
  }

  get nombreNoValido(): any {
    return this.datosForm.get('nombre').invalid && this.datosForm.get('nombre').touched;
  }

  get apellidoNoValido(): any {
    return this.datosForm.get('apellidos').invalid && this.datosForm.get('apellidos').touched;
  }

  get dniNoValido(): any {
    return this.datosForm.get('dni').invalid && this.datosForm.get('dni').touched;
  }

  get provinciaNoValido(): any {
    return this.datosForm.get('id_provincia').invalid && this.datosForm.get('id_provincia').touched;
  }

  get municipioNoValido(): any {
    return this.datosForm.get('id_municipio').invalid && this.datosForm.get('id_municipio').touched;
  }

  get direccionNoValido(): any {
    return this.datosForm.get('direccion').invalid && this.datosForm.get('direccion').touched;
  }

  get emailNoValido(): any {
    return this.datosForm.get('username').invalid && this.datosForm.get('username').touched;
  }

  get empresaNoValido(): any {
    return this.datosForm.get('id_centro').invalid && this.datosForm.get('id_centro').touched;
  }

  get departamentoNoValido(): any {
    return this.datosForm.get('id_departamento').invalid && this.datosForm.get('id_departamento').touched;
  }

  get pinNoValido(): any {
    return this.datosForm.get('pin').invalid && this.datosForm.get('pin').touched;
  }

  get contratoNoValido(): any {
    return this.datosForm.get('contrato').invalid && this.datosForm.get('contrato').touched;
  }

  get horasNoValido(): any {
    return this.datosForm.get('horas').invalid && this.datosForm.get('horas').touched;
  }

  crearFormulario(): any {
    this.datosForm = this.fb.group({
      id: null,
      img: ['images/centro/persona_default.png'],
      nombre: [{value: '', disabled: this.soloLectura}, Validators.required],
      apellidos: [{value: '', disabled: this.soloLectura}, Validators.required],
      dni: [{value: '', disabled: this.soloLectura}, [Validators.required,
        Validators.pattern('^[0-9]{8}([-]?)[a-zA-Z]$|^[xyzXYZ]([-]?)[0-9]{7}([-]?)[a-zA-Z]$')]],
      nacimiento: [{value: '', disabled: this.soloLectura}],
      id_provincia: [{value: '', disabled: this.soloLectura}, Validators.required],
      id_municipio: [{value: '', disabled: true}, Validators.required],
      direccion: [{value: '', disabled: this.soloLectura}, Validators.required],
      username: [{value: '', disabled: this.soloLectura}, [Validators.required,
        Validators.pattern('^[a-zA-Z0-9_\\-\\.~]{2,}@[a-zA-Z0-9_\\-\\.~]{2,}\\.[a-zA-Z]{2,4}$')]],
      id_centro: [{value: '', disabled: this.soloLectura}, Validators.required],
      id_departamento: [{value: '', disabled: true}, Validators.required],
      password: [{value: '', disabled: this.soloLectura}],
      pin: [{value: '', disabled: this.soloLectura}, Validators.required],
      contrato: [{value: '', disabled: this.soloLectura}, Validators.required],
      horas: [{value: '', disabled: this.soloLectura}, Validators.required]
    });
    this.modalForm = this.fb.group({
      jornada: [''],
      jornadaHoras: ['']
    });
  }

  fileChangeEvent(fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    let isImagen = false;

    for (let i = 0; i < this.filesToUpload.length; i++) {
      if (this.filesToUpload[i].type.match('image.*')) {
        isImagen = true;
        continue;
      }
    }

    if (isImagen) {
      const file = this.filesToUpload[0];
      const reader = new FileReader();
      reader.onload = e => this.imagen = reader.result;
      reader.readAsDataURL(file);
      this.cambiarImagen = true;
    } else {
      Swal.fire('', 'Debe elegir un archivo de tipo imagen', 'warning');
      this.imagen = null;
      this.filesToUpload = null;
    }
  }

  getMunicipios() {
    this._crud.getMunicipios(this.datosForm.value.id_provincia);
    this.municipiosSubscription = this._crud.municipios$.subscribe(res => {
      this.municipios = res;
      if (this.municipios.length > 0) {
        this.datosForm.controls['id_municipio'].enable();
      }
    });
  }

  getDepartamentos() {
    console.log(this.datosForm.value.id_centro)
    this._crud.getDepartamentos(this.datosForm.get('id_centro').value);
    this.departamentosSubscription = this._crud.departamentos$.subscribe(res => {
      this.departamentos = res;
      if (this.departamentos.length > 0) {
        this.datosForm.controls['id_departamento'].enable();
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  open(content) {
    this.modalService.open(content, {
      centered: true
    });
  }

  submit() {
    this.contrato.nombre = this.modalForm.value.jornada;
    this.contrato.horas = this.modalForm.value.jornadaHoras;
    this.setContrato();
  }

  setContrato() {
    this._crud.addContrato(this.contrato).subscribe(res => {
      if (res['code'] === 200) {
        this.contrato.contrato = res['id'];
        this.contratos.push(this.contrato);
        this.modalForm.reset();
      }
    }, error1 => {
      console.log(error1);
    });
  }

  Siguiente() {
    // console.log(this.datosForm.value);
    this._crud.postUsuario(this.datosForm.value).subscribe(data => {
      if (data['code'] === 200) {
        const res = data['id']
        if (this.cambiarImagen) {
          this._upload.makeFileRequest(res.id, this.filesToUpload, 'imagen', 'user').then((result: any) => {
            if (result) {
              console.log(result, 'con foto');
            }
          }).catch( error => {
            console.log(error);
          });
        } else {
          console.log(data['id'], 'sin foto');
        }
        if (this.dato === undefined) {
          this._crud.getEmpleado(res.id);
          this.userSubscription = this._crud.user$.subscribe(resp => {
            console.log(resp, 'ingreso dato');
            this.emisor.emit(resp);
          });
        }
      } else {
        if (data['code'] === 444 ) {
          Swal.fire('', 'El usuario existe pero esta deshabilitado', 'warning')
        }
      }
    });

  }

  setDatos(dato) {
    this.datosForm.controls.id.setValue(dato.id);
    this.datosForm.controls.img.setValue(dato.img);
    this.datosForm.controls.nombre.setValue(dato.nombre?.trim());
    this.datosForm.controls.apellidos.setValue(dato.apellidos?.trim());
    this.datosForm.controls.dni.setValue(dato.dni?.trim());
    this.datosForm.controls.nacimiento.setValue(dato.nacimiento?.trim());
    this.datosForm.controls.id_provincia.setValue(dato.id_provincia);
    this.getMunicipios();
    this.datosForm.controls.id_municipio.setValue(dato.id_municipio);
    this.datosForm.controls.direccion.setValue(dato.direccion);
    this.datosForm.controls.username.setValue(dato.username);
    this.datosForm.controls.id_centro.setValue(dato.id_centro);
    this.getDepartamentos();
    this.datosForm.controls.id_departamento.setValue(dato.id_departamento);
    this.datosForm.controls.password.setValue(dato.password);
    this.datosForm.controls.pin.setValue(dato.pin);
    this.datosForm.controls.contrato.setValue(dato.contrato);
    this.datosForm.controls.horas.setValue(dato.horas);
  }

  horas() {
    const dato = this.contratos.find(res => res.contrato === this.datosForm.value.contrato )
    this.datosForm.controls.horas.setValue(dato.horas);
  }
}
