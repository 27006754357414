import {Component, OnInit} from '@angular/core';
import {FicharService} from './fichar.service';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import swal from 'sweetalert2';

@Component({
    selector: 'app-fichar',
    templateUrl: './fichar.component.html',
    styleUrls: ['./fichar.component.css']
})
export class FicharComponent implements OnInit {
    public ficharSubscription = new Subscription();

    id_empleado: String = '';
    pin_empleado: String = '';
    estado_empleado: String = '';

    type: string;
    mensaje: String = '';
    title: String = '';
    timer: number;

    constructor(private _fichar: FicharService) {
    }

    ngOnInit() {
        this.ficharSubscription = this._fichar.respuesta$.subscribe(res => {
            this.alert(res);
        }, error => {
            this.alertError();
        });
    }

    borrar() {
        if (this.estado_empleado) {
            this.pin_empleado = this.pin_empleado.slice(0, this.pin_empleado.length - 1);
        } else {
            this.id_empleado = this.id_empleado.slice(0, this.id_empleado.length - 1);
        }
    }

    entrada() {
        this.estado_empleado = 'ENTRADA';
    }

    salida() {
        this.estado_empleado = 'SALIDA';
    }

    reset() {
        this.id_empleado = '';
        this.pin_empleado = '';
        this.estado_empleado = '';
    }

    fichar() {
        const empleado = {
            id: Number(this.id_empleado),
            pin: Number(this.pin_empleado),
            estado: this.estado_empleado
        };

        this._fichar.postfichaje(empleado);
    }

    alert(resultado: any) {
        try {
            if (resultado.code === 200) {
              swal.fire(resultado.id.nombre + ', has fichado.', (resultado.id.totalFichajes - resultado.id.fichajesRealizados) + ' fichajes restantes.', 'success');
            } else {
              swal.fire('', resultado.message, 'error');
            }
            this.reset();
        } catch (e) {
            this.alertError()
        }
    }

    alertError() {
        swal.fire('ERROR', 'Se ha producido un error inesperado', 'error');
        this.reset();
    }

    onKeydown(event: KeyboardEvent) {
        const numString = String.fromCharCode(event.keyCode);
        const numNumber = parseInt(String.fromCharCode(event.keyCode), 10);

        // Limpia el estilo pulsado de los botones
        for (let i = 0; i < 10; i++) {
            document.getElementById(String(i)).className = 'btn btn-secondary';
        }

        // Efecto botón pulsado
        document.getElementById(numString).className = 'btn btn-secondary btn-sample-pulsado';

        // Limpia el efecto del último btn pulsado tras 0,5 segundos
        setTimeout(limpiarBtnPulsado, 400);

        function limpiarBtnPulsado() {
            document.getElementById(numString).className = 'btn btn-secondary';
        }
    }

    clickNumero($event) {
      if (Number.parseInt($event.target.id, 10) >= 0 && Number.parseInt($event.target.id, 10) <= 9 && !this.estado_empleado) {
        this.id_empleado = this.id_empleado.concat($event.target.id);
      }

      if (Number.parseInt($event.target.id, 10) >= 0 && Number.parseInt($event.target.id, 10) <= 9 && this.estado_empleado) {
        this.pin_empleado = this.pin_empleado.concat($event.target.id);
      }
    }
}
