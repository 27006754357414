import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {EmpleadosService} from '../../empleados/services/empleados.service';
import {EmpresaService} from '../services/empresa.service';
import swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../../pages/services/login.service';


@Component({
    selector: 'app-empresa',
    templateUrl: './empresa.component.html',
    styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit, OnDestroy {

    private empresaSubscription: Subscription = null;
    empresas: any[] = [];


    @Output() empresaId: EventEmitter<number> = new EventEmitter();

    constructor(private _empresa: EmpleadosService,
                private _empresaRemove: EmpresaService,
                private router: Router,
                private route: ActivatedRoute,
                private _login: LoginService) {
    }

    ngOnInit() {
        this.empresaSubscription = this._empresa.empresas$.subscribe(res => {
            this.empresas = res;
        });
        this._empresa.getEmpresas();
        this._empresaRemove.getEmpresas();
    }

    ngOnDestroy() {
        this.empresaSubscription.unsubscribe();
    }


    verEmpresa(id) {
        this.empresaId.emit(id);
        this.router.navigate(['/admin/localizacion/', id]).then(r => r);
    }


    eliminarEmpresa(id: number) {
        const empresaNombre = this.empresas[this.empresas.findIndex(empresa => empresa.id === id)].nombre;
        swal.fire({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar ' + empresaNombre + '?',
            icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Aceptar!',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this._empresaRemove.removeEmpresa(id).subscribe(res => {
                    if (res['code'] === 200) {
                        swal.fire('', 'Se ha eliminado ' + empresaNombre + '.', 'success');
                        this.empresas.splice(this.empresas.findIndex(empresa => empresa.id === id), 1);
                    } else {
                        swal.fire('ERROR', 'Existen usuarios asignados en esta empresa.', 'error');
                    }
                }, error => {
                    console.log(error);
                });
            }
        });
    }
}
