import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import { ExportAsModule } from 'ngx-export-as';


// IDIOMA
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

// ROUTES
import {APP_ROUTING} from './app.routes';

// MODULOS
import {PagesModule} from './components/pages/pages-module';
import {SharedModule} from './components/template/shared.module';

// PLUGINS

import { EmpresaComponent } from './components/admin/componentes/empresa/empresaList/empresa.component';
import { DepartamentoComponent } from './components/admin/componentes/departamento/departamentoList/departamento.component';
import {AdminModule} from './components/admin/admin.module';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {MatStepperModule} from '@angular/material/stepper';
import {PdfComponent} from './components/admin/modales/pdf/pdf.component';





@NgModule({
    declarations: [
        AppComponent,
        EmpresaComponent,
        DepartamentoComponent
    ],
    imports: [
        BrowserModule,
        PagesModule,
        SharedModule,
        APP_ROUTING,
        ClickOutsideModule,
        ExportAsModule,
        NgbModule,
        NgxPaginationModule,
        AdminModule,
        MatStepperModule,
        CdkStepperModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'es'},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        NgbActiveModal
    ],
    exports: [
        EmpresaComponent
    ],
    bootstrap: [AppComponent],
  entryComponents: [
    PdfComponent
  ]
})
export class AppModule {
}
