import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, TitleCasePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../pipes.module';

/*** MAPA GOOGLE***/
import {AgmCoreModule} from '@agm/core';
import {MapId} from '../../utiles/Config';

// SERVICIOS
import {EmpleadosService} from './componentes/empleados/services/empleados.service';
import {CrudempleadosService} from './componentes/empleados/services/crudempleados.service';

// MODALES
import {MyModalComponent} from './modales/my-modal/my-modal.component';
import {PdfComponent} from './modales/pdf/pdf.component';

// COMPONENTES
import {AdminComponent} from './admin.component';
import {EmpleadosComponent} from './componentes/empleados/empleadoList/empleados.component';
import {AddempleadoComponent} from './componentes/empleados/empleadoAdd/addempleado.component';
import {SeguimientoComponent} from './componentes/empleados/seguimientoList/seguimiento.component';
import {DatosComponent} from './componentes/empleados/componentes/datos/datos.component';
import {ContactoComponent} from './componentes/empleados/componentes/contacto/contacto.component';
import {EstudiosComponent} from './componentes/empleados/componentes/estudios/estudios.component';
import {EditempleadoComponent} from './componentes/empleados/empleadoEdit/editempleado.component';
import {HorarioComponent} from './componentes/empleados/componentes/horario/horario.component';
import {ControlComponent} from './componentes/empleados/componentes/control/control.component';
import {EmpresaaddComponent} from './componentes/empresa/empresaAdd/empresaadd.component';
import {DepartamentoaaddComponent} from './componentes/departamento/departamentoAdd/departamentoaadd.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EmpresaLocalizacionComponent} from './componentes/empresa/empresa-localizacion/empresa-localizacion.component';
import {EmpresaLocalizacionMapComponent} from './componentes/empresa/empresa-localizacion-map/empresa-localizacion-map.component';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {ProyectosComponent} from './componentes/proyectos/proyectos.component';
import {MatButtonModule} from '@angular/material/button';
import {MinutosPipe} from '../../pipes/minutos.pipe';
import {HorasCalculoPipe} from '../../pipes/horas-calculo.pipe';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: MapId,
      libraries: ['places']
    }),
    MatTabsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatIconModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxPaginationModule,
    NgbModule,
    MatStepperModule,
    CdkStepperModule,
    MatButtonModule
  ],
  declarations: [
    MyModalComponent,
    PdfComponent,
    AdminComponent,
    EmpleadosComponent,
    AddempleadoComponent,
    SeguimientoComponent,
    DatosComponent,
    ContactoComponent,
    EstudiosComponent,
    EditempleadoComponent,
    HorarioComponent,
    ControlComponent,
    EmpresaaddComponent,
    DepartamentoaaddComponent,
    EmpresaLocalizacionComponent,
    EmpresaLocalizacionMapComponent,
    ProyectosComponent,
  ],
  providers: [
    EmpleadosService,
    CrudempleadosService,
    DatePipe,
    TitleCasePipe,
    MinutosPipe,
    HorasCalculoPipe
  ],

  exports: [
    AdminComponent,
    EmpleadosComponent,
    AddempleadoComponent,
    SeguimientoComponent,
    DatosComponent,
    ContactoComponent,
    EstudiosComponent,
    EditempleadoComponent,
    HorarioComponent,
    ControlComponent,
    EmpresaLocalizacionComponent,
  ]

})
export class AdminModule {
}
