// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'reordenar' })

// Definimos la clase implementado la interface PipeTransform
export class ReordenarArrayPipe implements PipeTransform {
  transform(value: any[], limite: number): any[] {
    const array = [];
    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      array.push(value[i]);
    }
    for (let i = 0; i < limite; i++) {
      const elemento = array.shift();
      array.push(elemento);
    }
    return array;
  }

}
