<div class="border-0" xmlns="http://www.w3.org/1999/html">
    <div class="card text-white bg-dark border-0 p-1 mb-3">
        <input type="text" class="form-control mb-2 font-personalizada-mapa" (change)="EmitirEvento()"
               (keydown.enter)="$event.preventDefault()"
               placeholder="Introduzca una dirección" autocorrect="off" autocapitalize="off" spellcheck="off" #search>
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>
        </agm-map>

        <form  [formGroup]="formularioCoordenadas" autocomplete="off">
            <div class="form-row">
                <div class="form-group col-4" style="display: none">
                    <label for="inputLongitud">Longitud</label>
                    <input type="hidden" step="any" class="form-control" name="longitude" formControlName="longitude"
                           id="inputLongitud" [value]="longitude" #longitudView>
                </div>
                <div class="form-group col-4" style="display: none">
                    <label for="inputLatitud">Latitud</label>
                    <input type="hidden" step="any" class="form-control" name="latitude" formControlName="latitude"
                           id="inputLatitud" [value]="latitude" #latitudView>
                </div>
                <div class="form-group col-9 p-3">
                    <label>Dirección</label>
                    <br/>
                    <label style="color: white">{{address}}</label>

                </div>
                <div class="form-group col-3 p-3">
                    <label for="inputRadio">Distancia Máxima </label>
                    <input type="number" step="any" class="form-control" name="radio" formControlName="radio"
                           id="inputRadio" [value]="distanciaHijo" #radioView>
                    <p  class="badge badge-info">Distancia en metros </p>
                </div>

            </div>
        </form>
    </div>
</div>



