import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { RutasImg } from '../../utiles/rutas';
import { LoginService } from '../pages/services/login.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
    selector: 'app-shared',
    templateUrl: './shared.component.html'
})
export class SharedComponent implements OnInit, OnDestroy {
    @ViewChild('wrapper') wraper: ElementRef;
    private datos: any = {};
    private sidebar: string;
    private user: string;

    private sidebarSubscriber: Subscription = null;
    private userSubscription: Subscription = null;

    constructor(private _login: LoginService, private renderer: Renderer2) {
        this.datos = JSON.parse(localStorage.getItem('identity'));

    }

    ngOnInit() {
        this.sidebarSubscriber = this._login.$lateralImage.subscribe(data => {
            this.sidebar = RutasImg + data;
        });
        this.userSubscription = this._login.$userImage.subscribe(data => {
            this.user = RutasImg + data;
        });
    }

    ngOnDestroy() {
        if (this.userSubscription != null) {
            this.userSubscription.unsubscribe();
        }
    }

    desplegar() {
        const elemento = document.getElementById('wrapper').firstElementChild.firstElementChild.firstElementChild;
        elemento.classList.toggle('toggled');
    }

}
