// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'mes' })

// Definimos la clase implementado la interface PipeTransform
export class NombreMesPipe implements PipeTransform {
  transform(value: string): string {
    let valor = '';
    switch (value) {
      case 'January':
        valor = 'Enero';
        break;
      case 'February':
        valor = 'Febrero';
        break;
      case 'March':
        valor = 'Marzo';
        break;
      case 'April':
        valor = 'Abril';
        break;
      case 'May':
        valor = 'Mayo';
        break;
      case 'June':
        valor = 'Junio';
        break;
      case 'July':
        valor = 'Julio';
        break;
      case 'August':
        valor = 'Agosto';
        break;
      case 'September':
        valor = 'Septiembre';
        break;
      case 'October':
        valor = 'Octubre';
        break;
      case 'November':
        valor = 'Noviembre';
        break;
      case 'December':
        valor = 'Diciembre';
        break;
      case 'Monday':
        valor = 'Lunes';
        break;
      case 'Tuesday':
        valor = 'Martes';
        break;
      case 'Wednesday':
        valor = 'Miércoles';
        break;
      case 'Thursday':
        valor = 'Jueves';
        break;
      case 'Friday':
        valor = 'Viernes';
        break;
      case 'Saturday':
        valor = 'Sábado';
        break;
      case 'Sunday':
        valor = 'Domingo';
        break;
    }
    return valor;
  }

}
