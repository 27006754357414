import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../utiles/rutas';
import 'rxjs/add/operator/map';


@Injectable()
export class LoginService {

    private authSource = new BehaviorSubject(false);
    public auth$ = this.authSource.asObservable();

    // Bool observable para controlar si el login es ok o no

    private errorSource = new BehaviorSubject(false);
    public error$ = this.errorSource.asObservable();

    private headerImageSource = new BehaviorSubject<any>(false);
    public $headerImage = this.headerImageSource.asObservable();

    private logoImageSource = new BehaviorSubject<any>(false);
    public logoImage$ = this.logoImageSource.asObservable();

    private lateralImageSource = new BehaviorSubject<any>(false);
    public $lateralImage = this.lateralImageSource.asObservable();

    private userImageSource = new BehaviorSubject<any>(false);
    public $userImage = this.userImageSource.asObservable();

    private roleSource = new Subject<string>();
    public role$ = this.roleSource.asObservable();

    private activateSource = new Subject<any>();
    public activate$ = this.activateSource.asObservable();

    private updateSource = new BehaviorSubject<any>(false);
    public update$ = this.updateSource.asObservable();


    constructor(private http: HttpClient, private router: Router) {
    }

    forgotPassword(value: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {value};
        const data = JSON.stringify(json);
        const params = 'json=' + data;
        this.http.post(Rutas + 'forgot/password', params, {headers: headers}).subscribe(datas => {
            if (datas['code'] === 200) {
                if (datas['id']) {
                    this.updateSource.next(true);
                }
            }
        }, error => {
            // console.log(error);
            return error;
        });
    }

    updatePassword(value: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {value};
        const data = JSON.stringify(json);
        const params = 'json=' + data;
        this.http.post(Rutas + 'update/password', params, {headers: headers}).subscribe(datas => {
            if (datas['code'] === 200) {
                if (datas['id']) {
                    this.updateSource.next(true);
                }
            }
        }, error => {
            // console.log(error);
            return error;
        });
    }

    validateToken(token: string) {
        this.http.get(Rutas + 'validate/token?token=' + token).subscribe(data => {
            if (data['code'] === 200) {
                this.activateSource.next(data['id']);
            }
        }, err => {
            // console.log(err.message);
            return err;
        });
    }


    getUserImage() {
        if (this.getIdentity()) {
            this.userImageSource.next(this.getIdentity().img);
        }
    }

    getLogo() {
        this.http.get(Rutas + 'get/logo').subscribe(resp => {
            if (resp['code'] === 200) {
                // console.log(resp['id'].logo);
                this.logoImageSource.next(resp['id']);
            } else {
                // console.log(resp['id']);
                this.logoImageSource.next('not_logo.png');
            }
        });
    }

    getImages() {
        if (this.getIdentity()) {
            this.http.get(Rutas + 'get/images?token=' + this.getToken() + '&id=' + this.getIdentity().centro.id).subscribe(data => {
                if (data['code'] === 200) {
                    this.headerImageSource.next(data['id'].header);
                    this.lateralImageSource.next(data['id'].sidebar);
                    return;
                }
            }, err => {
                // console.log(err.message);
                return err;
            });
        } else {
            this.headerImageSource.next('images/centro/not_header.jpg');
            this.lateralImageSource.next('images/centro/not_sidebar.jpg');
            this.logoImageSource.next('images/centro/not_logo.png');
        }
    }

    login(user: string, password: string) {

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {username: user, password: password};
        const data = JSON.stringify(json);
        const params = 'json=' + data;
        console.log(params);

         this.http.post(Rutas + 'login', params, {headers: headers}).subscribe(datas => {
            if (datas['code'] === 200) {
                localStorage.setItem('identity', JSON.stringify(datas['id']['id']));
                localStorage.setItem('token', datas['id']['token']);
                this.authSource.next(true);
                this.roleSource.next(this.getIdentity().role);
                // Variable Observable para ver si lo credenciales estan bien
                this.errorSource.next(false);
            } else {
                this.errorSource.next(true);
            }

        }, error => {
             console.log('ERROR', error);
         });

    }

    getIdentity() {
        const identity = JSON.parse(localStorage.getItem('identity'));
        if (identity !== null) {
            return identity;
        }
        return false;
    }

    getToken() {
        // console.log('token', token);
        const token = localStorage.getItem('token');
        if (token !== null) {
            return token;
        }
        return false;
    }

    checkToken() {
        this.http.get(Rutas + 'checktoken?token=' + this.getToken()).subscribe(data => {
            if (data['code'] === 200) {
                this.authSource.next(true);
            } else {
                this.logout();
            }
        });
    }

    authGuardAdmin(): Promise<boolean> {
        const url = Rutas + 'check/role/admin?token=' + this.getToken() + '&id=' + this.getIdentity().id;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response === 'true') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('GET', url, true);
            xhr.send();
        });
    }

    authGuardMaestro(): Promise<boolean> {
        const url = Rutas + 'check/role/maestro?token=' + this.getToken() + '&id=' + this.getIdentity().id;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response === 'true') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('GET', url, true);
            xhr.send();
        });
    }

    authGuardAlumno(): Promise<boolean> {
        const url = Rutas + 'check/role/alumno?token=' + this.getToken() + '&id=' + this.getIdentity().id;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response === 'true') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('GET', url, true);
            xhr.send();
        });
    }

    authGuardPadre(): Promise<boolean> {
        const url = Rutas + 'check/role/padre?token=' + this.getToken() + '&id=' + this.getIdentity().id;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response === 'true') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('GET', url, true);
            xhr.send();
        });
    }

    logout() {
        localStorage.clear();
        this.authSource.next(false);
        this.router.navigate(['/public/login']);
    }
}
