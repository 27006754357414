// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';
import { RutasImg } from '../utiles/rutas';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'imagesInbox'})

// Definimos la clase implementado la interface PipeTransform
export class ImagesInboxPipe implements PipeTransform {
transform(value: string, tipo: string): string {
    const url = RutasImg + tipo + value;
    return url;
 }
}
