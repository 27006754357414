import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../services/login.service';
import {Subscription} from 'rxjs/Subscription';
import {RutasImg} from '../../../utiles/rutas';


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
    @ViewChild('f') loginForm: NgForm;
    private authSubscription: Subscription = new Subscription();
    private logoSubscription: Subscription = new Subscription();
    private roleSubscription: Subscription = new Subscription();
    private errorSubscription: Subscription = new Subscription();

    public error: boolean;
    public logo: string;
    public title: string;
    public saveUsername: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private _login: LoginService,
    ) {
        // console.log('LOGIN', this._login.getToken());

        if (this._login.getIdentity()) {

            this._login.checkToken();
            this._login.getImages();
        }
    }

    ngOnDestroy() {
        this.authSubscription.unsubscribe();
        this.logoSubscription.unsubscribe();
        this.roleSubscription.unsubscribe();
        this.errorSubscription.unsubscribe();
    }

    ngOnInit() {

         console.log('Init login');

         this.errorSubscription = this._login.error$.subscribe( error => {
             this.error = error;
         });
        this.authSubscription = this._login.auth$.subscribe(auth => {
            if (auth) {
                this.roleSubscription = this._login.role$.subscribe(res => {
                    if (res === 'ROLE_ADMIN') {
                        this.router.navigate(['/admin/admin/'], {
                            relativeTo: this.route
                        });
                    } else if (res === 'ROLE_PROFESOR') {
                        // console.log('SOY MAESTRO');
                        this.router.navigate(['/admin/editempleado/' + this._login.getIdentity().id], {
                            relativeTo: this.route
                        });
                        // console.log('IDENTITY: ', this._login.getIdentity());
                    }

                });
            }
        });

        this.logoSubscription = this._login.logoImage$.subscribe(data => {
            this.logo = RutasImg + data.logo;
            this.title = data.title;
        });
    }

     onSubmit() {
        if (this.loginForm.valid) {
          this._login.login(
             this.loginForm.value.username,
             this.loginForm.value.password
         );

            console.log(this.error);
        }
    }

    // On Forgot password link click
    onForgotPassword() {
        this.router.navigate(['forgot'], {relativeTo: this.route.parent});
    }


}
