import {Component, OnDestroy, OnInit} from '@angular/core';
import {DepartamentoServiceService} from '../services/departamento-service.service';
import {Subscription} from 'rxjs/Subscription';
import swal from 'sweetalert2';

@Component({
  selector: 'app-departamento',
  templateUrl: './departamento.component.html',
  styleUrls: ['./departamento.component.css']
})
export class DepartamentoComponent implements OnInit, OnDestroy {

  private departamentoSubscription: Subscription = null;
  departamentos: any[] = [];

  constructor(public _departamento: DepartamentoServiceService) {
  }

  ngOnInit() {
    this.departamentoSubscription = this._departamento.departamentos$.subscribe(res => {
      this.departamentos = res;
    });

    this._departamento.getDepartamentos();
  }

  ngOnDestroy() {
    this.departamentoSubscription.unsubscribe();
  }

  eliminarDepartamento(id: number) {
    const nombreDepartamento = this.departamentos[this.departamentos.findIndex(departamento => departamento.id === id)].departnombre;
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar ' + nombreDepartamento + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Aceptar!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._departamento.removeDepartamento(id).subscribe(res => {
          if (res['code'] === 200) {
            swal.fire('', 'Se ha eliminado ' + nombreDepartamento + '.', 'success');
            this.departamentos.splice(this.departamentos.findIndex(departamento => departamento.id === res['id']), 1);
          } else {
            swal.fire('ERROR', 'Existen usuarios asignados en este departamento.', 'error');
          }
        }, error => {
          // console.log(error);
          return error;
        });
      }
    });
  }
}
