import {Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {Datos} from '../../../../../modelos/datos.modelo';
import {DatosComponent} from '../componentes/datos/datos.component';
import {ContactoComponent} from '../componentes/contacto/contacto.component';
import {EstudiosComponent} from '../componentes/estudios/estudios.component';
import {CrudempleadosService} from '../services/crudempleados.service';
import {UploadService} from '../../../../services/upload.service';
import {HorarioComponent} from '../componentes/horario/horario.component';
import {MatStepper} from '@angular/material/stepper';

@Component({
  selector: 'app-addempleado',
  templateUrl: './addempleado.component.html',
})
export class AddempleadoComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('datos', {static: false}) datos: DatosComponent;
  @ViewChild('contacto', {static: false}) contacto: ContactoComponent;
  @ViewChild('estudios', {static: false}) estudios: EstudiosComponent;
  @ViewChild('horario', {static: false}) horario: HorarioComponent;
  @ViewChild('stepper') stepper: MatStepper;

  public userSubscription = new Subscription();
  public filesToUpload: Array<File>;
  public user: Datos;
  public arreglados: any[];
  public empresa: any;
  public iduser: any;
  public inicio: Date;
  public fin: Date;
  isEditable = true;
  empleado: any;
  id: any;
  ok: boolean;

  constructor(
    private _route: ActivatedRoute, private _router: Router, private _crud: CrudempleadosService, private _upload: UploadService,
    private cdRef: ChangeDetectorRef) {
    this.ok = this._router.url === '/admin/addempleado';
    this._route.params.subscribe(res => {
      this.id = res.id;
      console.log(res.id);
      if (res.id) {
        this._crud.getEmpleado(res.id);
        this.userSubscription = this._crud.user$.subscribe(resp => {
          console.log(resp);
          this.empleado = resp;
        });
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  get empleadoDatos(): any {
    return this.datos ? this.datos.datos$ : null;
  }

  guardar() {
    this._router.navigate([
      '/admin/empleados'
    ]);
  }

  datosEmpleado($event: any) {
   this.empleado = $event;
  }
}
