import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../../../utiles/rutas';
import {LoginService} from '../../../../pages/services/login.service';
import {Router} from '@angular/router';

@Injectable()
export class CrudempleadosService {
  private provinciasSource = new Subject<any>();
  public provincias$ = this.provinciasSource.asObservable();

  private municipiosSource = new Subject<any>();
  public municipios$ = this.municipiosSource.asObservable();

  private empresasSource = new Subject<any>();
  public empresas$ = this.empresasSource.asObservable();

  private empresaSource = new Subject<any>();
  public empresa$ = this.empresaSource.asObservable();

  private departamentosSource = new Subject<any>();
  public departamentos$ = this.departamentosSource.asObservable();

  private datoSource = new Subject<any>();
  public dato$ = this.datoSource.asObservable();

  private userSource = new Subject<any>();
  public user$ = this.userSource.asObservable();

  private contratosSource = new Subject<any>();
  public contratos$ = this.contratosSource.asObservable();

  private contratoSource = new Subject<any>();
  public contrato$ = this.contratoSource.asObservable();

  constructor(private http: HttpClient, private _login: LoginService,
              private router: Router) {

  }

  getProvincias() {
    this.http.get(Rutas + 'provincias?token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log(localStorage.getItem('token'));
      if (data['code'] === 200) {
        this.provinciasSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getMunicipios(id: number) {
    this.http.get(Rutas + 'municipios?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        // console.log(data['id']);
        this.municipiosSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  postUsuario(user: any) {
    user.role = 2;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    if (user.id === null) {
      return this.http.post(Rutas + 'add/new/usuario', params, {headers: headers});
    } else {
      return this.http.post(Rutas + 'edit/datos/usuario', params, {headers: headers});
    }
  }

  getEmpresas() {
    this.http.get(Rutas + 'get/empresas?token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        this.empresasSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getEmpresa(id: Number) {
    this.http.get(Rutas + 'get/userempresa?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        this.empresaSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getDepartamentos(id: Number) {
    this.http.get(Rutas + 'get/departamentos?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        console.log(data['id']);
        this.departamentosSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getEmpleado(id) {
    this.http.get(Rutas + 'get/empleado?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        this.userSource.next(data['id']);
      }
    }, error => {
      // console.log(error);
      return error;
    });
  }

  addContacto(contacto: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {contacto};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');

    return this.http.post(Rutas + 'add/contacto', params, {headers: headers});
  }

  addTitulo(titulo: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {titulo};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');

    return this.http.post(Rutas + 'add/titulo', params, {headers: headers});
  }

  removeTitulo(id: Number, idt: number) {
    return this.http.get(Rutas + 'remove/titulo?id=' + id + '&id_titulo=' + idt + '&token=' + localStorage.getItem('token'))
  }

  removeContacto(id: Number, idc: number) {
    return this.http.get(Rutas + 'remove/contacto?id=' + id + '&id_contacto=' + idc + '&token=' + localStorage.getItem('token'));
  }

  addHorario(horario: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {horario};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');

    return this.http.post(Rutas + 'add/horario', params, {headers: headers});
  }

  addContrato(contrato: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {contrato};
    const data = JSON.stringify(json);
    const params = 'token=' + localStorage.getItem('token') + '&json=' + data;

    return this.http.post(Rutas + 'add/contratos', params, {headers: headers});

  }

  removeHorario(id: Number, idt: number) {
    return this.http.get(Rutas + 'remove/horario?id=' + id + '&id_horario=' + idt + '&token=' + localStorage.getItem('token'));
  }

  getContratos() {
    this.http.get(Rutas + 'get/contratos?token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        this.contratosSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  addDepartamento(departamento: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {departamento};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');

    this.http.post(Rutas + 'add/new/departamento', params, {headers: headers}).subscribe(res => {
      // console.log(res);
      if (res['code'] === 200) {
        console.log('Departamento Insertado');
        this.router.navigateByUrl('admin/departamento');
      }
    }, error => {
      console.log(error);
    });
  }
}
