import {Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {LoginService} from '../../../../../pages/services/login.service';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {MatStepper} from '@angular/material/stepper';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  encapsulation: ViewEncapsulation.None
})

export class HorarioComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dato: Datos;
  horarioForm: FormGroup;
  @Output() public emisor = new EventEmitter<any>();
  @Input() stepper: MatStepper;
  public dias: any[];
  public horas: any[];
  role = '';
  horario$: Observable<FormGroup>;
  horarios: any[] = [];

  private horarioOb$ = new BehaviorSubject<FormGroup>(null);
  horarioListener$: Observable<FormGroup> = this.horarioOb$.asObservable();

  constructor(private _route: ActivatedRoute, private _router: Router, private _crud: CrudempleadosService, private _login: LoginService,
              private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.role = this._login.getIdentity().role;
    this.dias = [{valor: 1, nombre: 'Lunes'}, {valor: 2, nombre: 'Martes'}, {valor: 3, nombre: 'Miércoles'},
      {valor: 4, nombre: 'Jueves'}, {valor: 5, nombre: 'Viernes'}, {valor: 6, nombre: 'Sabado'}, {valor: 0, nombre: 'Domingo'},
    ];
    this.horas = [
      {valor: 0, nombre: '00'}, {valor: 1, nombre: '01'}, {valor: 2, nombre: '02'}, {valor: 3, nombre: '03'},
      {valor: 4, nombre: '04'}, {valor: 5, nombre: '05'}, {valor: 6, nombre: '06'}, {valor: 7, nombre: '07'},
      {valor: 8, nombre: '08'}, {valor: 9, nombre: '09'}, {valor: 10, nombre: '10'}, {valor: 11, nombre: '11'},
      {valor: 12, nombre: '12'}, {valor: 13, nombre: '13'}, {valor: 14, nombre: '14'}, {valor: 15, nombre: '15'},
      {valor: 16, nombre: '16'}, {valor: 17, nombre: '17'}, {valor: 18, nombre: '18'}, {valor: 19, nombre: '19'},
      {valor: 20, nombre: '20'}, {valor: 21, nombre: '21'}, {valor: 22, nombre: '22'}, {valor: 23, nombre: '23'},
    ];
    this.crearFormulario();
  }

  setForm(form: FormGroup): any {
    this.horarioOb$.next(form);
  }

  ngOnInit() {
    this.setForm(this.horarioForm);
    this.horario$ = this.horarioListener$.pipe(delay(0));
  }

  ngOnDestroy() {
  }

  ngOnChanges() {
    if (this.dato) {
      this.horarios = this.dato.horarios;
    }
    this.cdRef.detectChanges();
  }

  crearFormulario(): any {
    this.horarioForm = this.fb.group({
      id: null,
      dia: ['', Validators.required],
      in: ['', Validators.required],
      out: ['', Validators.required],
      margen: ['', Validators.required]
    });
  }

  open() {
    if (this.horarioForm.value.dia && this.horarioForm.value.in && this.horarioForm.value.out && this.horarioForm.value.margen) {
      if (!this.horarios) {
        this.horarios = [];
      }
      const horario = {
        id: this.dato.id,
        dia: this.horarioForm.value.dia,
        inat: this.horarioForm.value.in,
        outat: this.horarioForm.value.out,
        margen: this.horarioForm.value.margen,
      }
      this._crud.addHorario(horario).subscribe(res => {
        // console.log(res);
        if (res['code'] === 200) {
          const resp = res['id'];
          this.horarios = resp.horarios;
        }
      }, error => {
        console.log(error);
        return error;
      });
    } else {
      Swal.fire('', 'Faltan datos por completar', 'warning');
    }
  }


  borrar(i) {
    const idt = this.horarios[i].id;
    this._crud.removeHorario(this.dato.id, idt).subscribe(data => {
      // console.log(data);
      if (data['code'] === 200) {
        const res  = data['id'];
        this.horarios = res.horarios;
      }
    }, error => {
      console.log(error);
    });
  }


  diasemana(i: number) {
    if (i === 0) {
      return 'Domingo';
    } else {
      return this.dias[i - 1].nombre;
    }
  }

  controlPaso(): boolean {
    if (!this.horarios) {
      this.horarios = [];
    }
    console.log(this.horarios);
    return this.horarios.length > 0;
  }

  Siguiente3() {
    if (!this.controlPaso()) {
      Swal.fire('', 'Es obligtorio ingresar al menos un Horario', 'warning')
    } else {
      this.stepper.next();
    }
  }
}
